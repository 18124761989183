import React, { Component } from "react";
import currencyFormatter from "currency-formatter";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import FileBase64 from "react-file-base64";
import FileBase64Component from "../../AppInputToBase64";
import Popup from "reactjs-popup";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
} from "react-stripe-elements";

// Packages
import Select from "react-select";
import MaskedTextInput from "react-text-mask";
import TextareaAutosize from "react-autosize-textarea";

// API
import * as API from "../../../../utils/api";
import Tabs from "../../../../components/Tabs";
// Components
import Modal from "../Modal";
import pdfIcon from "../../../../assets/images/icons/pdf.png";

import {
  amountMask,
  isValidDate,
  getStrippedDollarAmount,
  showToast,
} from "../../../../utils/helpers";
import { includes } from "lodash";
import moment from "moment";
import Emitter from "../../../../utils/event-emitter";

const styles = {
  checkboxDiv: {
    display: "flex",
    margin: "0.5em 0px",
    alignItems: "center",
  },
  checkbox: {
    WebkitAppearance: `checkbox`,
    width: `16px`,
    marginRight: `10px`,
  },
  acceptAgr: {
    fontSize: 12,
    fontStyle: "italic",
    fontWeight: "bold",
  },
};
class MakePaymentModal extends Component {
  state = {
    creditors: [],
    userData: this.props.user,
    isAutoPopulateBalanceDue: false,
    activeForm: 1,
    account: {
      invoice_id: this.props.optionalProps.hasOwnProperty("debtInfo")
        ? this.props.optionalProps.debtInfo.id
        : "",
      debtBalance: this.props.optionalProps.hasOwnProperty("debtInfo")
        ? this.props.optionalProps.debtInfo.balance
        : this.props.optionalProps.fromConsumer
        ? this.props.optionalProps.totalBalance
        : "",
    },
    paymentDocument: "",
    input: {
      paymentInformation: {
        paymentType: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        paymentAmount: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        date_of_payment: {
          value: "",
          hasError: false,
          isRequired: true,
        },
        note: {
          value: "",
          hasError: false,
        },
        document: {
          value: "",
          name: "",
          hasError: false,
        },
      },
      CardInformation: {
        nameOnCard: {
          value: "",
          empty: true,
          hasError: false,
        },
        cardNumber: {
          value: "",
          empty: true,
          hasError: false,
        },
        cardExpirationDate: {
          value: "",
          empty: true,
          hasError: false,
        },
        cvc: {
          value: "",
          empty: true,
          hasError: false,
        },
        cardZip: {
          value: "",
          empty: true,
          hasError: false,
        },
      },
    },
    isLoading: true,
    docErrorMsg: "",
    error: {
      hasAnyError: false,
      statusCode: 200,
      message: "",
    },
    balanceToReceive: 0.0,
    savedCard: {
      status: false,
      last4: null,
      sourceID: null,
      tempStatus: false,
    },
  };

  componentDidMount() {
    let temp = { ...this.state.input };
    let { optionalProps } = this.props;
    temp.paymentInformation.date_of_payment.value = moment(new Date()).format(
      "MM/DD/YYYY"
    );
    this.setState({ input: temp });
    if (
      optionalProps.hasOwnProperty("receivePayment") &&
      optionalProps.receivePayment
    ) {
      this.setState((state) => ({
        ...state,
        balanceToReceive: parseFloat(
          optionalProps.paymentPlansDetails.balance_as_currency.replace(",", "")
        ),
      }));
    }

    if (
      optionalProps.hasOwnProperty("receivePaymentForInvoices") &&
      optionalProps.receivePaymentForInvoices
    ) {
      let bal = 0;
      optionalProps.debts.map((item, index) => {
        if (
          item.hasOwnProperty("selectionCheck") &&
          item.selectionCheck === true
        ) {
          bal = bal + parseFloat(item.debt_invoice.balance.replace(",", ""));
        }
      });

      this.setState((state) => ({
        ...state,
        balanceToReceive: parseFloat(bal),
      }));
    }
    this.getMiscellaneousSettingStatus(this.props.user);
  }

  handleModalClose = () => {
    if (!this.state.error.hasAnyError) {
      this.props.hideModal();
    }
  };

  getDateFormat = (date) => {
    let tempdate = new Date(date).getTime();
    return tempdate;
  };

  handlePaymentInfoInputChange(newPartialInput) {
    if (
      newPartialInput.paymentType &&
      newPartialInput.paymentType.value &&
      newPartialInput.paymentType.value === "CreditCard"
    ) {
      this.getSavedCard(this.props.user);
    }
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        paymentInformation: {
          ...state.input.paymentInformation,
          ...newPartialInput,
        },
      },
    }));
  }

  handleInputBlur = (type) => {
    let { input } = this.state;
    let { paymentInformation } = input;
    if (type && paymentInformation[type]) {
      let value = paymentInformation[type].value;
      if (value && !value.includes(".")) {
        input.paymentInformation[type].value = value + ".00";
      }
    }
    this.setState({ input });
  };

  getFormattedAmount = (amount) => {
    if (amount) {
      amount = parseFloat(amount).toFixed(2);
    } else {
      amount = 0.0;
    }
    return amount;
  };

  // Callback~
  getFiles(files) {
    let newInputState = { ...this.state.input.paymentInformation };
    let imgExtension = files[0].name.split(".")[1];
    let extensionFlag = false;
    let sizeFlag = false;
    let isError = false;
    if (
      imgExtension.toLowerCase() === "png" ||
      imgExtension.toLowerCase() === "jpg" ||
      imgExtension.toLowerCase() === "jpeg" ||
      imgExtension.toLowerCase() === "bmp" ||
      imgExtension.toLowerCase() === "pdf" ||
      imgExtension.toLowerCase() === "pdf"
    ) {
      extensionFlag = true;
    }
    if (files[0].size <= 1024) {
      // extensionFlag = false;
      sizeFlag = true;
    }

    if (extensionFlag && sizeFlag) {
      this.setState({ document: files[0].base64 });
      newInputState.document.value = files[0].base64;
      newInputState.document.name = files[0].name;
      this.handlePaymentInfoInputChange({
        document: {
          value: files[0].base64,
          name: files[0].name,
          hasError: false,
        },
      });
    } else {
      let errorMSG;
      newInputState.document.hasError = true;
      isError = true;
      if (!extensionFlag) {
        errorMSG = "File not supported.";
      } else if (extensionFlag && !sizeFlag) {
        errorMSG = "File size should not be greater than 1MB.";
      }
      this.handlePaymentInfoInputChange({
        document: {
          value: "",
          hasError: isError,
        },
      });
      this.setState({ docErrorMsg: errorMSG });
    }
  }

  handleAccountSettlement = (stripeToken, paymentInfo) => {
    if (this.state.savedCard.status || this.state.savedCard.tempStatus) {
      paymentInfo.source_id = stripeToken;
    } else {
      paymentInfo.card_token = stripeToken;
    }
    API.paymentByChequeOrCash(this.props.user, {
      ...paymentInfo,
      return_url: null,
    }).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        !data.error &&
        data.status_code === 200
      ) {
        this.props.updateModalState(true, "SUCCESS", { message: data.message });
        this.setState({ isLoading: false });
        this.props.optionalProps.reloadData(true);
      } else {
        if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else if (data.status_code == 400) {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.handleModalClose();
      }
    });
  };

  getMiscellaneousSettingStatus = (user) => {
    const { optionalProps } = this.props;
    API.getMiscellaneousInSettings(user)
      .then((data) => {
        if (typeof data != "undefined" || data != null) {
          if (data.data) {
            if (data.data[0].data[0].is_enable) {
              this.setState({
                isAutoPopulateBalanceDue: data.data[0].data[0].is_enable,
              });
              this.amountAfterDiscount();
            }
          }
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            error: {
              hasAnyError: true,
              message: data && data.error ? data.error : "",
              statusCode: data
                ? data.status ||
                  (data.message && data.message == "Access denied."
                    ? 401
                    : 500) ||
                  500
                : 500,
            },
          }));
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  handleReceiveMultiInvoicePaymentByCard = (stripeToken, paymentInfo) => {
    let tempPaymentInfo = paymentInfo;
    if (this.state.savedCard.status || this.state.savedCard.tempStatus) {
      tempPaymentInfo = {
        ...paymentInfo,
        source_id: stripeToken,
        card_token: null,
      };
    } else {
      tempPaymentInfo.card_token = stripeToken;
    }
    API.receiveMultipleInvoicesPayment(this.props.user, {
      ...tempPaymentInfo,
      return_url: null,
    }).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.status_code === 400) {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        }
        this.setState({ isLoading: false });
        this.props.optionalProps.reloadData(true);
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.handleModalClose();
      }
    });
  };

  handleReceivePaymentStripe = (stripeToken) => {
    let { input } = this.state;
    let tempArr = [];
    this.props.optionalProps.receivePaymentList.map((item, index) => {
      if (item.hasOwnProperty("planCheck")) {
        if (item.planCheck) {
          tempArr.push(item.id);
        }
      }
    });
    let paymentInfo = {
      payment_plan_id: this.props.optionalProps.paymentPlanId,
      amount: getStrippedDollarAmount(
        input.paymentInformation.paymentAmount.value
      ),
      payment_date: moment(
        input.paymentInformation.date_of_payment.value
      ).format("YYYY-MM-DD"),
      // payment_ids: tempArr,
      payment_type: input.paymentInformation.paymentType.value,
      note: input.paymentInformation.note.value,
      document: input.paymentInformation.document.value,
      nameOnCard: input.CardInformation.nameOnCard.value,
      cardNumber: input.CardInformation.cardNumber.value,
      cardExpirationDate: input.CardInformation.cardExpirationDate.value,
      cvc: input.CardInformation.cvc.value,
      cardZip: input.CardInformation.cardZip.value,
      card_token: stripeToken,
      return_url: null,
    };
    if (this.state.savedCard.status || this.state.savedCard.tempStatus) {
      paymentInfo = {
        ...paymentInfo,
        source_id: stripeToken,
        card_token: null,
      };
    }
    API.receivePaymentByChequeOrCash(
      this.props.user,
      paymentInfo,
      this.props.optionalProps.paymentPlanId
    ).then((data) => {
      if ((typeof data != "undefined" || data != null) && !data.error) {
        if (data.status_code === 400) {
          this.props.updateModalState(true, "ERROR", { message: data.message });
        } else if (data.status_code === 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else {
          this.props.updateModalState(true, "SUCCESS", {
            message: data.message,
          });
        }
        this.setState({ isLoading: false });
        this.props.optionalProps.reloadData(true);
      } else {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data && data.error ? data.error : "",
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.handleModalClose();
      }
    });
  };

  handleSubmit = (event, type) => {
    $("#receivePayment").replaceWith('<div class="spinner"></div>');
    event.preventDefault();
    let { input } = this.state;
    let { optionalProps } = this.props;
    let { debts } = optionalProps;
    if (type === "Receive Payment") {
      let tempArr = [];
      if (
        optionalProps.hasOwnProperty("receivePaymentForInvoices") &&
        optionalProps.receivePaymentForInvoices
      ) {
        debts.map((item, index) => {
          if (item.hasOwnProperty("selectionCheck")) {
            if (item.selectionCheck) {
              tempArr.push(item.debt_invoice.id);
            }
          }
        });
      }
      let paymentInfo = {
        payment_type: input.paymentInformation.paymentType.value,
        amount: getStrippedDollarAmount(
          input.paymentInformation.paymentAmount.value
        ),
        last_payment_date: moment(
          input.paymentInformation.date_of_payment.value
        ).format("YYYY-MM-DD"),
        note: input.paymentInformation.note.value,
        paymentDocument: input.paymentInformation.document.value,
        invoice_ids: tempArr,
        nameOnCard: input.CardInformation.nameOnCard.value,
        cardNumber: input.CardInformation.cardNumber.value,
        cardExpirationDate: input.CardInformation.cardExpirationDate.value,
        cvc: input.CardInformation.cvc.value,
        cardZip: input.CardInformation.cardZip.value,
      };
      if (this.state.savedCard.status) {
        this.recievePaymentHandling(paymentInfo);
      }
      if (this.validateCCForm()) {
        if (
          !(
            this.state.userData.last_4 &&
            this.state.userData.last_4 != null &&
            this.state.userData.last_4 != undefined
          )
        ) {
          if (this.validateCCForm()) {
            $("#submit-form").replaceWith('<div class="spinner"></div>');
            setTimeout(() => {
              this._element.focus();
              this._element.blur();
            }, 20);

            if (
              optionalProps.hasOwnProperty("receivePayment") &&
              optionalProps.receivePayment
            ) {
              this.props.stripe
                .createToken({
                  name: this.state.input.CardInformation.nameOnCard.value,
                })
                .then(({ token }) => {
                  this.recievePaymentHandling(paymentInfo, token);
                });
            }
            if (
              optionalProps.hasOwnProperty("receivePaymentForInvoices") &&
              optionalProps.receivePaymentForInvoices
            ) {
              this.props.stripe
                .createToken({
                  name: this.state.input.CardInformation.nameOnCard.value,
                })
                .then(({ token }) => {
                  this.recievePaymentHandling(paymentInfo, token);
                });
            }
          }
        }
      }
    } else if (type === "Accept Payment") {
      $("#acceptPayment").replaceWith('<div class="spinner"></div>');

      let paymentInfo = {
        type: input.paymentInformation.paymentType.value,
        amount: getStrippedDollarAmount(
          input.paymentInformation.paymentAmount.value
        ),
        last_payment_date: moment(
          input.paymentInformation.date_of_payment.value
        ).format("YYYY-MM-DD"),
        note: input.paymentInformation.note.value,
        paymentDocument: input.paymentInformation.document.value,
        invoice_id: this.props.optionalProps.hasOwnProperty("debtInfo")
          ? this.props.optionalProps.debtInfo.id
          : "",
        nameOnCard: input.CardInformation.nameOnCard.value,
        cardNumber: input.CardInformation.cardNumber.value,
        cardExpirationDate: input.CardInformation.cardExpirationDate.value,
        cvc: input.CardInformation.cvc.value,
        cardZip: input.CardInformation.cardZip.value,
      };

      if (
        this.state.userData.last_4 &&
        this.state.userData.last_4 != null &&
        this.state.userData.last_4 != undefined
      ) {
        //this.handleAccountSettlement('');
      } else {
        if (this.state.savedCard.status) {
          this.acceptPaymentHandling(paymentInfo);
        } else {
          if (this.validateCCForm()) {
            $("#submit-form").replaceWith('<div class="spinner"></div>');
            setTimeout(() => {
              this._element.focus();
              this._element.blur();
            }, 20);
            this.props.stripe
              .createToken({
                name: this.state.input.CardInformation.nameOnCard.value,
              })
              .then(({ token }) => {
                this.acceptPaymentHandling(paymentInfo, token);
              })
              .finally(() => {});
          }
        }
      }
    }
  };

  handleAcceptPayment = (paymentInfo) => {
    $("#form-1").replaceWith('<div class="spinner"></div>');
    API.paymentByChequeOrCash(this.props.user, {
      ...paymentInfo,
      return_url: null,
    }).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        !data.error &&
        data.status_code === 200
      ) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.setState({ isLoading: false });
        this.props.optionalProps.reloadData(true);
      } else {
        if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else if (data.status_code == 400) {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data.error,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.handleModalClose();
      }
    });
  };

  handleReceivePayment = (paymentInfo) => {
    $("#form-1").replaceWith('<div class="spinner"></div>');
    API.receivePaymentByChequeOrCash(
      this.props.user,
      { ...paymentInfo, return_url: null },
      this.props.optionalProps.paymentPlanId
    ).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code === 200
      ) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.setState({ isLoading: false });
        this.props.optionalProps.reloadData(true);
      } else {
        if (data.status_code === 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else if (data.status_code == 400) {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data.error,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.handleModalClose();
      }
    });
  };
  handleReceiveInvoicePayment = (paymentInfo) => {
    $("#form-1").replaceWith('<div class="spinner"></div>');
    /////id id id id id id
    API.receiveMultipleInvoicesPayment(
      this.props.user,
      { ...paymentInfo, return_url: null },
      this.props.optionalProps.paymentPlanId
    ).then((data) => {
      if (
        (typeof data != "undefined" || data != null) &&
        data.status_code === 200
      ) {
        this.props.updateModalState(true, "SUCCESS", {
          message: data.message,
        });
        this.setState({ isLoading: false });
        this.props.optionalProps.reloadData(true);
      } else {
        if (data.status_code == 422) {
          Emitter.emit("NOTIFICATION_ALERT", true);
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        } else if (data.status_code == 400) {
          this.props.updateModalState(true, "ERROR", {
            message: data.message,
          });
        }
        this.setState((state) => ({
          ...state,
          isLoading: false,
          error: {
            hasAnyError: true,
            message: data.error,
            statusCode: data
              ? data.status ||
                (data.message && data.message == "Access denied."
                  ? 401
                  : 500) ||
                500
              : 500,
          },
        }));
        this.handleModalClose();
      }
    });
  };
  handleMakePaymentClick = (event, type) => {
    event.preventDefault();
    let { input } = this.state;
    let { optionalProps } = this.props;
    let { receivePaymentList, paymentPlanId, fromConsumer, debts } =
      optionalProps;
    if (fromConsumer) {
      this.setState((state) => ({
        ...state,
        activeForm: 2,
      }));
    } else if (type === "Receive Payment") {
      this.getSavedCard(this.props.user);
      let tempArr = [];
      if (
        optionalProps.hasOwnProperty("receivePayment") &&
        optionalProps.receivePayment
      ) {
        receivePaymentList.map((item, index) => {
          if (item.hasOwnProperty("planCheck")) {
            if (item.planCheck) {
              tempArr.push(item.id);
            }
          }
        });
        if (this.validateForm()) {
          let paymentInfo = {};
          if (input.paymentInformation.document.value == "") {
            paymentInfo = {
              payment_plan_id: paymentPlanId,
              amount: getStrippedDollarAmount(
                input.paymentInformation.paymentAmount.value
              ),
              payment_date: moment(
                input.paymentInformation.date_of_payment.value
              ).format("YYYY-MM-DD"),
              // payment_ids: tempArr,
              payment_type: input.paymentInformation.paymentType.value,
              note: input.paymentInformation.note.value,
              document: input.paymentInformation.document.value,
            };
          } else {
            paymentInfo = {
              payment_plan_id: paymentPlanId,
              amount: getStrippedDollarAmount(
                input.paymentInformation.paymentAmount.value
              ),
              payment_date: moment(
                input.paymentInformation.date_of_payment.value
              ).format("YYYY-MM-DD"),
              payment_type: input.paymentInformation.paymentType.value,
              note: input.paymentInformation.note.value,
              document: input.paymentInformation.document.value,
              document_type: "Payment Plan",
              attachment_name: input.paymentInformation.document.name,
              description: "Receive Payment",
            };
          }
          input.paymentInformation.paymentType.value === "CreditCard"
            ? this.setState((state) => ({
                ...state,
                activeForm: 2,
              }))
            : this.handleReceivePayment(paymentInfo);
        }
      }
      if (
        optionalProps.hasOwnProperty("receivePaymentForInvoices") &&
        optionalProps.receivePaymentForInvoices
      ) {
        debts.map((item, index) => {
          if (item.hasOwnProperty("selectionCheck")) {
            if (item.selectionCheck) {
              tempArr.push(item.debt_invoice.id);
            }
          }
        });
        if (this.validateForm()) {
          let paymentInfo = {};
          if (input.paymentInformation.document.value == "") {
            paymentInfo = {
              amount: getStrippedDollarAmount(
                input.paymentInformation.paymentAmount.value
              ),
              payment_date: moment(
                input.paymentInformation.date_of_payment.value
              ).format("YYYY-MM-DD"),
              payment_type: input.paymentInformation.paymentType.value,
              invoice_ids: tempArr,
              note: input.paymentInformation.note.value,
              paymentDocument: input.paymentInformation.document.value,
            };
          } else {
            paymentInfo = {
              amount: getStrippedDollarAmount(
                input.paymentInformation.paymentAmount.value
              ),
              payment_date: moment(
                input.paymentInformation.date_of_payment.value
              ).format("YYYY-MM-DD"),
              payment_type: input.paymentInformation.paymentType.value,
              invoice_ids: tempArr,
              note: input.paymentInformation.note.value,
              paymentDocument: input.paymentInformation.document.value,
              document_type: "Invoice",
              attachment_name: input.paymentInformation.document.name,
              description: "Receive Payment",
            };
          }
          input.paymentInformation.paymentType.value === "CreditCard"
            ? this.setState((state) => ({
                ...state,
                activeForm: 2,
              }))
            : this.handleReceiveInvoicePayment(paymentInfo);
        }
      }
    } else if (type === "Accept Payment") {
      if (this.validateForm()) {
        this.getSavedCard(this.props.user);
        let paymentInfo;
        if (input.paymentInformation.document.value == "") {
          paymentInfo = {
            type: input.paymentInformation.paymentType.value,
            amount: getStrippedDollarAmount(
              input.paymentInformation.paymentAmount.value
            ),
            last_payment_date: moment(
              input.paymentInformation.date_of_payment.value
            ).format("YYYY-MM-DD"),
            note: input.paymentInformation.note.value,
            invoice_id: this.props.optionalProps.hasOwnProperty("debtInfo")
              ? this.props.optionalProps.debtInfo.id
              : "",
            paymentDocument: input.paymentInformation.document.value,
          };
        } else {
          paymentInfo = {
            type: input.paymentInformation.paymentType.value,
            amount: getStrippedDollarAmount(
              input.paymentInformation.paymentAmount.value
            ),
            last_payment_date: moment(
              input.paymentInformation.date_of_payment.value
            ).format("YYYY-MM-DD"),
            note: input.paymentInformation.note.value,
            invoice_id: this.props.optionalProps.hasOwnProperty("debtInfo")
              ? this.props.optionalProps.debtInfo.id
              : "",
            paymentDocument: input.paymentInformation.document.value,
            attachment_name: input.paymentInformation.document.name,
            description: "Accept Payment",
            document_type: "Invoice",
          };
        }
        if (
          paymentInfo.amount <=
          getStrippedDollarAmount(
            this.props.optionalProps.hasOwnProperty("debtInfo")
              ? this.props.optionalProps.debtInfo.balance
              : 0.0
          )
        ) {
          if (input.paymentInformation.paymentType.value === "CreditCard") {
            this.setState((state) => ({
              ...state,
              activeForm: 2,
            }));
          } else {
            this.handleAcceptPayment(paymentInfo);
          }
        } else {
          let newInputState = { ...this.state.input.paymentInformation };
          newInputState["paymentAmount"].hasError = true;
          this.setState((state) => ({
            ...state,
            paymentInformation: newInputState,
          }));
        }
      }
    }
  };

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  calculateDiscountedBalance(debtInfo) {
    if (!debtInfo) return 0.0;

    // Default values
    const balance = parseFloat(debtInfo.balance || 0.0);
    const totalAmount = parseFloat(debtInfo.total_amount || 0.0);
    const criteriaDiscount = parseFloat(debtInfo.criteria_discount || 0.0);

    // Calculate discount
    if (debtInfo.criteria_discount_percent_based) {
      const discount = (totalAmount / 100) * criteriaDiscount;
      return balance - discount;
    } else {
      return balance - criteriaDiscount;
    }
  }

  getUserID() {
    const { optionalProps } = this.props;
    let userID = "";
    const type =
      optionalProps.receivePayment || optionalProps.receivePaymentForInvoices
        ? "Receive Payment"
        : "Accept Payment";

    if (type === "Receive Payment") {
      if (optionalProps.modal === "paymentPlan") {
        userID = optionalProps.paymentPlansDetails.user.id;
      } else if (optionalProps.modal === "multipleInvoices") {
        userID = optionalProps.consumerId;
      } else {
        userID = optionalProps.debtInfo.user_id;
      }
    } else {
      if (optionalProps.debtInfo.user_id) {
        userID = optionalProps.debtInfo.user_id;
      } else {
        userID = optionalProps.debtInfo.user.user_id;
      }
    }

    return userID;
  }

  getSavedCard = (user) => {
    let userID = this.getUserID();
    API.getCardInfo(user, userID).then((data) => {
      console.log("data of saved card ==>>", data.data);
      if (data.status_code == 200 && data.data.length > 0) {
        data.data.map((item) => {
          if (item.object === "card") {
            console.log("saving card===>>", item);
            this.setState({
              savedCard: {
                status: true,
                last4: item.last4,
                sourceID: item.source_id,
              },
            });
          }
        });
      }
    });
  };

  removeCreditCard = (user, payload) => {
    let userID = this.getUserID();
    API.removeCardInfo(user, payload, userID).then((data) => {
      if (data.status_code === 200) {
        this.setState({
          savedCard: {
            status: false,
            tempStatus: false,
            last4: null,
            sourceID: null,
          },
        });
      }
    });
  };

  addCreditCard = (user, token, paymentInfo) => {
    const { optionalProps } = this.props;
    let userID = this.getUserID();

    API.addCardInfo(user, { cardToken: token.id }, userID).then((data) => {
      if (data.status_code == 200 && data.cardAdded) {
        this.setState(
          {
            savedCard: {
              status: true,
            },
          },
          () => {
            if (
              optionalProps.hasOwnProperty("receivePayment") &&
              optionalProps.receivePayment
            ) {
              this.handleReceivePaymentStripe(token.card.id);
            } else if (
              optionalProps.hasOwnProperty("receivePaymentForInvoices") &&
              optionalProps.receivePaymentForInvoices
            ) {
              this.handleReceiveMultiInvoicePaymentByCard(
                token.card.id,
                paymentInfo
              );
            } else {
              this.handleAccountSettlement(token.card.id, paymentInfo);
            }
          }
        );
        return true;
      }
    });
  };

  acceptPaymentHandling = (paymentInfo, token) => {
    if (this.state.savedCard.status) {
      this.handleAccountSettlement(this.state.savedCard.sourceID, paymentInfo);
    } else if (this.state.savedCard.tempStatus) {
      this.addCreditCard(this.props.user, token, paymentInfo);
    } else {
      this.handleAccountSettlement(token.id, paymentInfo);
    }
  };

  recievePaymentHandling = (paymentInfo, token) => {
    const { optionalProps } = this.props;
    if (
      optionalProps.hasOwnProperty("receivePayment") &&
      optionalProps.receivePayment
    ) {
      if (this.state.savedCard.status) {
        this.handleReceivePaymentStripe(this.state.savedCard.sourceID);
      } else if (this.state.savedCard.tempStatus) {
        this.addCreditCard(this.props.user, token, paymentInfo);
      } else {
        this.handleReceivePaymentStripe(token.id);
      }
    } else {
      if (this.state.savedCard.status) {
        this.handleReceiveMultiInvoicePaymentByCard(
          this.state.savedCard.sourceID,
          paymentInfo
        );
      } else if (this.state.savedCard.tempStatus) {
        this.addCreditCard(this.props.user, token, paymentInfo);
      } else {
        this.handleReceiveMultiInvoicePaymentByCard(token.id, paymentInfo);
      }
    }
  };

  validateForm = () => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = { ...this.state.input.paymentInformation };
    let { debtInfo } = this.props.optionalProps;
    // Check inputs for errors
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "paymentType") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "paymentAmount") {
        if (
          this.props.optionalProps.hasOwnProperty("receivePayment") ||
          this.props.optionalProps.hasOwnProperty("receivePaymentForInvoices")
        ) {
          if (
            value.value === "" ||
            parseFloat(value.value.replace(",", "").replace("$", "")) >
              this.state.balanceToReceive.toFixed(2) ||
            parseFloat(value.value.replace(",", "").replace("$", "")) <= 0
          ) {
            errorsArePresent = true;
            newInputState[key].hasError = true;
          }
        } else {
          if (
            value.value === "" ||
            getStrippedDollarAmount(value.value) >
              getStrippedDollarAmount(debtInfo.balance) ||
            getStrippedDollarAmount(value.value) < 1
          ) {
            errorsArePresent = true;
            newInputState[key].hasError = true;
          }
        }
      } else if (key === "date_of_payment") {
        if (
          !isValidDate(value.value) ||
          value.value === "" ||
          value.value === null
        ) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "document") {
        if (value.hasError === true) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === "") {
        errorsArePresent = true;
      }
    });

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        paymentInformation: newInputState,
      }));
    } else {
      return true;
    }
  };

  validateCCForm = () => {
    let errorsArePresent = false;

    // Determine which input group to check for errors
    let newInputState = { ...this.state.input.CardInformation };

    // Check inputs for errors
    Object.entries(newInputState).forEach(([key, value]) => {
      if (key === "nameOnCard") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "cardNumber") {
        if (value.value === "") {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "cardExpirationDate") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "cvc") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (key === "cardZip") {
        if (value.value === "" || value.value === null) {
          errorsArePresent = true;
          newInputState[key].hasError = true;
        }
      } else if (value.isRequired && value.value === "") {
        errorsArePresent = true;
      }
    });

    let { input } = this.state;
    let checkErrors =
      input.CardInformation.cardNumber.hasError ||
      input.CardInformation.cardExpirationDate.hasError ||
      input.CardInformation.cvc.hasError ||
      input.CardInformation.cardZip.hasError;
    if (checkErrors) {
      errorsArePresent = true;
    }

    if (errorsArePresent) {
      this.setState((state) => ({
        ...state,
        paymentInformation: newInputState,
      }));
    } else {
      return true;
    }
  };

  renderErrorMessage(input) {
    let message = "";
    if (input === "paymentType") {
      message = "Please select payment type.";
    } else if (input === "paymentAmount") {
      message = "Please enter valid amount.";
    } else if (input === "date_of_payment") {
      message = "Please enter valid date.";
    } else if (input === "nameOnCard") {
      message = "Please enter Name on card";
    } else if (input === "cardNumber") {
      message = "Please enter valid credit card number";
    } else if (input === "cardExpirationDate") {
      message = "Please enter Expiration date";
    } else if (input === "cvc") {
      message = "Please enter CVV";
    } else if (input === "cardZip") {
      message = "Please enter billing Zip code";
    } else if (input === "paymentAmount") {
      message = "Please enter amount";
    } else {
      message = "Please complete this field.";
    }

    return <div className='input-error-message'>{message}</div>;
  }

  handleInputChange(newPartialInput) {
    this.setState((state) => ({
      ...state,
      input: {
        ...state.input,
        CardInformation: {
          ...state.input.CardInformation,
          ...newPartialInput,
        },
      },
    }));
  }

  handleTabClick = (tabIndex) => {
    const { input } = this.state;
    switch (parseInt(tabIndex, 10)) {
      case 1:
        this.setState((state) => ({
          ...state,
          activeForm: 1,
        }));
        // eslint-disable-next-line no-unreachable
        break;
      case 2:
        // this.setState(state => ({
        //   ...state,
        //   activeForm : 2
        // }))
        // eslint-disable-next-line no-unreachable
        break;
      default:
        return null;
        // eslint-disable-next-line no-unreachable
        break;
    }
  };

  renderTabsOrHeading = () => {
    const { input } = this.state;
    // let {optionalProps} = this.props
    if (input.paymentInformation.paymentType.value === "CreditCard") {
      const tabLabels = ["Pay", "Credit Card"];

      return (
        <Tabs
          labels={tabLabels}
          activeTarget={this.state.activeForm}
          handleTabChange={this.handleTabClick}
          optionalInTabClasses='mg-tabs-tab'
        />
      );
    } else {
      return "";
    }
  };

  amountAfterDiscount() {
    let { optionalProps } = this.props;
    let { debtInfo } = optionalProps;
    let early_payer_balance = 0.0;
    let is_discount_present = false;
    let isAmountPaid = false;
    if (
      optionalProps.hasOwnProperty("receivePaymentForInvoices") &&
      optionalProps.receivePaymentForInvoices
    ) {
      let invoiceList = optionalProps.debts.filter(
        (item) => item.selectionCheck
      );
      isAmountPaid = invoiceList.some(
        (item) => item.payment_details.length > 0
      );
      invoiceList.forEach((list) => {
        let creditMemoAmount = 0.0;

        // Check for credit memo payment details and calculate the amount
        list.payment_details.forEach((detail) => {
          if (detail.payment_type === "Credit Memo") {
            creditMemoAmount += parseFloat(detail.amount);
          }
        });

        if (list.early_payer_discount > 0) {
          is_discount_present = true;
          const totalAmount = parseFloat(list.debt_invoice.total_amt);
          const taxAmount = parseFloat(list.tax_amount);
          const earlyPayerDiscount = parseFloat(list.early_payer_discount);

          early_payer_balance +=
            totalAmount + taxAmount - creditMemoAmount - earlyPayerDiscount;
        } else {
          early_payer_balance += parseFloat(list.debt_invoice.balance);
        }
      });

      if (this.state.isAutoPopulateBalanceDue) {
        this.setState({
          input: {
            ...this.state.input,
            paymentInformation: {
              ...this.state.input.paymentInformation,
              paymentAmount: {
                ...this.state.input.paymentInformation.paymentAmount,
                value: !isAmountPaid
                  ? early_payer_balance.toFixed(2)
                  : this.state.balanceToReceive.toFixed(2),
              },
            },
          },
        });
      }
    } else {
      let afterdiscountAmount = this.getFormattedAmount(
        debtInfo && optionalProps.criteriaDiscountFlag
          ? debtInfo.hasOwnProperty("criteria_discount_percent_based") &&
            debtInfo.criteria_discount_percent_based
            ? parseFloat(
                debtInfo.hasOwnProperty("balance") ? debtInfo.balance : 0.0
              ) -
              (parseFloat(
                debtInfo.hasOwnProperty("total_amount")
                  ? debtInfo.total_amount
                  : 0.0
              ) /
                100) *
                parseFloat(
                  debtInfo.hasOwnProperty("criteria_discount")
                    ? debtInfo.criteria_discount
                    : 0.0
                )
            : parseFloat(
                debtInfo.hasOwnProperty("balance") ? debtInfo.balance : 0.0
              ) -
              parseFloat(
                debtInfo.hasOwnProperty("criteria_discount")
                  ? debtInfo.criteria_discount
                  : 0.0
              )
          : 0.0
      );

      let tempAmount;
      isAmountPaid = optionalProps.amountPaid > 0;

      if (this.state.isAutoPopulateBalanceDue) {
        if (afterdiscountAmount) {
          if (isAmountPaid) {
            tempAmount = currencyFormatter
              .format(debtInfo.balance, { code: "USD" })
              .replace("$", "");
          } else {
            tempAmount = currencyFormatter
              .format(afterdiscountAmount, { code: "USD" })
              .replace("$", "");
          }

          this.setState({
            input: {
              ...this.state.input,
              paymentInformation: {
                ...this.state.input.paymentInformation,
                paymentAmount: {
                  ...this.state.input.paymentInformation.paymentAmount,
                  value: `$${tempAmount}`,
                },
              },
            },
          });
        } else {
          tempAmount = currencyFormatter
            .format(optionalProps.debtInfo.balance, {
              code: "USD",
            })
            .replace("$", "");
          this.setState({
            input: {
              ...this.state.input,
              paymentInformation: {
                ...this.state.input.paymentInformation,
                paymentAmount: {
                  ...this.state.input.paymentInformation.paymentAmount,
                  value: `$${tempAmount}`,
                },
              },
            },
          });
        }
      }
    }
  }

  render() {
    let { input, slide, error } = this.state;
    let { stripePubKey, optionalProps } = this.props;
    let { debtInfo } = optionalProps;
    let { paymentDocument } = this.state;
    let iconImage = paymentDocument ? paymentDocument.document : "";
    let early_payer_balance = 0.0;
    let is_discount_present = false;
    let isAmountPaid = false;
    if (
      optionalProps.hasOwnProperty("receivePaymentForInvoices") &&
      optionalProps.receivePaymentForInvoices
    ) {
      let invoiceList = optionalProps.debts.filter(
        (item) => item.selectionCheck
      );
      isAmountPaid = invoiceList.some(
        (item) => item.payment_details.length > 0
      );
      invoiceList.map((list) => {
        let tempVal = 0.0;

        if (list.early_payer_discount > 0) {
          is_discount_present = true;
          list.payment_details.length &&
            list.payment_details.map((i) => {
              if (i.payment_type === "Credit Memo") {
                tempVal = parseFloat(i.amount);
              }
            });

          early_payer_balance =
            early_payer_balance +
            (parseFloat(list.debt_invoice.total_amt) +
              parseFloat(list.tax_amount) -
              parseFloat(tempVal) -
              parseFloat(list.early_payer_discount));
        } else {
          early_payer_balance =
            early_payer_balance + parseFloat(list.debt_invoice.balance);
        }
      });
    }
    if (
      paymentDocument &&
      paymentDocument.document &&
      paymentDocument.document.indexOf("pdf") !== -1
    ) {
      iconImage = pdfIcon;
    } else {
      iconImage = paymentDocument ? paymentDocument.document : "";
    }

    const CCstyle = {
      base: {
        fontSize: "18px",
        lineHeight: "1.875em",
        color: "#252525",
        fontFamily:
          "HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica",
        "::placeholder": {
          color: "#9B9B9B",
        },
      },
      invalid: {
        color: "#fb5c82",
        ":focus": {
          color: "#252525",
        },
      },
    };

    const borderBottom = {
      borderBottom: "1px solid #252525",
    };

    if (error.hasAnyError) {
      if (error.statusCode == 500) {
        this.props.updateModalState(true, "ERROR", { message: error.message });
        this.setState({
          error: {
            ...this.state.error,
            hasAnyError: false,
            statusCode: 200,
          },
        });
      } else if (error.statusCode == 401) {
        return <Redirect to={{ pathname: "/sign-out" }} />;
      } else {
        this.props.updateModalState(true, "OTHER_ERROR", true);
        return <Redirect to={{ pathname: "/sign-out" }} />;
      }
    }

    return (
      <Modal
        title={
          (optionalProps.hasOwnProperty("receivePayment") &&
            optionalProps.receivePayment) ||
          (optionalProps.hasOwnProperty("receivePaymentForInvoices") &&
            optionalProps.receivePaymentForInvoices)
            ? "Receive Payment"
            : "Accept Payment"
        }
        closeOnClickOutside={true}
        hideModal={this.props.hideModal}
        style={{ maxWidth: `35em` }}
        optionalClasses='scroll'
      >
        {this.renderTabsOrHeading()}
        <form>
          {this.state.activeForm === 1 ? (
            <div>
              <div>
                <div style={style.container}>
                  <div style={style.content}>Balance Due : </div>
                  <div className='input-container'>
                    {(optionalProps.hasOwnProperty("receivePayment") &&
                      optionalProps.receivePayment) ||
                    (optionalProps.hasOwnProperty(
                      "receivePaymentForInvoices"
                    ) &&
                      optionalProps.receivePaymentForInvoices) ? (
                      <p
                        style={{
                          marginBottom: "0px",
                          marginTop: "5px",
                          color: "#000",
                        }}
                      >
                        {` $ `}
                        {this.state.balanceToReceive.toFixed(2)}
                      </p>
                    ) : (
                      <MaskedTextInput
                        guide={true}
                        disabled={true}
                        mask={amountMask}
                        name='debtbBalance'
                        placeholder='$0'
                        placeholderChar={"\u2000"}
                        type='text'
                        value={this.getFormattedAmount(
                          debtInfo
                            ? debtInfo.balance
                            : optionalProps.fromConsumer
                            ? optionalProps.totalBalance
                            : 0.0
                        )}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display:
                      optionalProps.hasOwnProperty("criteriaDiscountFlag") &&
                      optionalProps.criteriaDiscountFlag
                        ? "block"
                        : "none",
                  }}
                >
                  <div
                    style={{
                      display: optionalProps.amountPaid > 0 ? "none" : "flex",
                    }}
                  >
                    <div style={style.content}>
                      <div>Early Payer Discount : </div>
                      <div style={{ fontSize: "10px" }}>
                        (If paid in full by{" "}
                        {moment(
                          optionalProps.hasOwnProperty("targetDate")
                            ? optionalProps.targetDate
                            : null
                        ).format("MM/DD/YYYY")}
                        )
                      </div>
                    </div>
                    <div className='input-container'>
                      <MaskedTextInput
                        guide={true}
                        disabled={true}
                        mask={amountMask}
                        name='criteriaDiscount'
                        placeholder='$0'
                        placeholderChar={"\u2000"}
                        type='text'
                        value={this.getFormattedAmount(
                          debtInfo && debtInfo.criteria_discount_percent_based
                            ? (parseFloat(
                                debtInfo.total_amount
                                  ? debtInfo.total_amount
                                  : 0.0
                              ) /
                                100) *
                                parseFloat(
                                  debtInfo.criteria_discount
                                    ? debtInfo.criteria_discount
                                    : 0.0
                                )
                            : parseFloat(
                                debtInfo && debtInfo.criteria_discount
                                  ? debtInfo.criteria_discount
                                  : 0.0
                              )
                        )}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: optionalProps.amountPaid > 0 ? "none" : "flex",
                    }}
                  >
                    <div style={style.content}>Early Payer Balance Due : </div>
                    <div className='input-container'>
                      <MaskedTextInput
                        guide={true}
                        disabled={true}
                        mask={amountMask}
                        name='earlyPayerBal'
                        placeholder='$0'
                        placeholderChar={"\u2000"}
                        type='text'
                        value={this.getFormattedAmount(
                          debtInfo
                            ? debtInfo.hasOwnProperty(
                                "criteria_discount_percent_based"
                              ) && debtInfo.criteria_discount_percent_based
                              ? parseFloat(
                                  debtInfo.hasOwnProperty("balance")
                                    ? debtInfo.balance
                                    : 0.0
                                ) -
                                (parseFloat(
                                  debtInfo.hasOwnProperty("total_amount")
                                    ? debtInfo.total_amount
                                    : 0.0
                                ) /
                                  100) *
                                  parseFloat(
                                    debtInfo.hasOwnProperty("criteria_discount")
                                      ? debtInfo.criteria_discount
                                      : 0.0
                                  )
                              : parseFloat(
                                  debtInfo.hasOwnProperty("balance")
                                    ? debtInfo.balance
                                    : 0.0
                                ) -
                                parseFloat(
                                  debtInfo.hasOwnProperty("criteria_discount")
                                    ? debtInfo.criteria_discount
                                    : 0.0
                                )
                            : 0.0
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display:
                      optionalProps.hasOwnProperty(
                        "receivePaymentForInvoices"
                      ) &&
                      optionalProps.receivePaymentForInvoices &&
                      !isAmountPaid &&
                      is_discount_present
                        ? "block"
                        : "none",
                  }}
                >
                  <div style={style.container}>
                    <div style={style.content}>Early Payer Balance Due : </div>
                    <div className='input-container'>
                      <MaskedTextInput
                        guide={true}
                        disabled={true}
                        mask={amountMask}
                        name='earlyPayerBal'
                        placeholder='$0'
                        placeholderChar={"\u2000"}
                        type='text'
                        value={early_payer_balance.toFixed(2)}
                      />
                    </div>
                  </div>
                </div>
                <div style={style.container}>
                  <div style={style.content}>Payment Amount : </div>
                  <div
                    className={`input-container${
                      input.paymentInformation.paymentAmount.hasError
                        ? " error"
                        : ""
                    }`}
                  >
                    <MaskedTextInput
                      guide={true}
                      mask={amountMask}
                      name='creditorTotalDebt'
                      placeholder='$0'
                      placeholderChar={"\u2000"}
                      pattern='^(\d+)(,\d{1,2}|.\d{1,2})?$'
                      type='text'
                      onBlur={() => this.handleInputBlur("paymentAmount")}
                      value={input.paymentInformation.paymentAmount.value}
                      onChange={(event) => {
                        if (
                          optionalProps.hasOwnProperty("receivePayment") ||
                          optionalProps.hasOwnProperty(
                            "receivePaymentForInvoices"
                          )
                        ) {
                          if (
                            parseFloat(
                              event.target.value
                                .replace(",", "")
                                .replace("$", "")
                            ) <= this.state.balanceToReceive.toFixed(2) &&
                            parseFloat(
                              event.target.value
                                .replace(",", "")
                                .replace("$", "")
                            ) > 0
                          ) {
                            this.handlePaymentInfoInputChange({
                              paymentAmount: {
                                value: event.target.value,
                                hasError: false,
                              },
                            });
                          } else {
                            this.handlePaymentInfoInputChange({
                              paymentAmount: {
                                value: event.target.value,
                                hasError: true,
                              },
                            });
                          }
                        } else {
                          this.handlePaymentInfoInputChange({
                            paymentAmount: {
                              value: event.target.value,
                              hasError: false,
                            },
                          });
                        }
                      }}
                    />
                    {input.paymentInformation.paymentAmount.hasError
                      ? this.renderErrorMessage("paymentAmount")
                      : null}
                  </div>
                </div>
                <div style={style.container}>
                  <div style={style.content}>Payment Type : </div>
                  <div
                    className={`input-container${
                      input.paymentInformation.paymentType.hasError
                        ? " error"
                        : ""
                    }`}
                  >
                    <Select
                      inputProps={{
                        autoComplete: "none",
                        autoCorrect: "off",
                        spellCheck: "off",
                      }}
                      className='form-select'
                      name='Select'
                      value={input.paymentInformation.paymentType.value}
                      placeholder='Select payment type'
                      options={[
                        { value: "Cash", label: "Cash" },
                        { value: "Check", label: "Check" },
                        { value: "CreditCard", label: "Credit Card" },
                      ]}
                      resetValue=''
                      onChange={(event) =>
                        this.handlePaymentInfoInputChange({
                          paymentType: { value: event.value, hasError: false },
                        })
                      }
                    />
                    {input.paymentInformation.paymentType.hasError
                      ? this.renderErrorMessage("paymentType")
                      : null}
                  </div>
                </div>
                <div>
                  <div style={style.container}>
                    <div style={style.content}>Payment Date : </div>
                    <div
                      className={`input-container${
                        input.paymentInformation.date_of_payment.hasError
                          ? " error"
                          : ""
                      }`}
                    >
                      <MaskedTextInput
                        guide={true}
                        mask={[
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        name='birthday'
                        placeholder='mm/dd/yyyy'
                        placeholderChar={"\u2000"}
                        type='text'
                        value={input.paymentInformation.date_of_payment.value}
                        onChange={(event) =>
                          this.handlePaymentInfoInputChange({
                            date_of_payment: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                      {input.paymentInformation.date_of_payment.hasError
                        ? this.renderErrorMessage("date_of_payment")
                        : null}
                    </div>
                  </div>
                  <div style={style.container}>
                    <div style={style.content}>Note : </div>
                    <div
                      className={`input-container${
                        input.paymentInformation.note.hasError ? " error" : ""
                      }`}
                    >
                      <TextareaAutosize
                        name='note'
                        type='textarea'
                        placeholder='Your note here...'
                        value={input.paymentInformation.note.value}
                        disabled={
                          optionalProps.hasOwnProperty(
                            "receivePaymentForInvoices"
                          ) && optionalProps.receivePaymentForInvoices
                            ? true
                            : false
                        }
                        onChange={(event) =>
                          this.handlePaymentInfoInputChange({
                            note: {
                              value: event.target.value,
                              hasError: false,
                            },
                          })
                        }
                      />
                      {input.paymentInformation.note.hasError
                        ? this.renderErrorMessage("note")
                        : null}
                    </div>
                  </div>
                  <div className='admin-form-row address-document'>
                    <div className={`input-container error`}>
                      {paymentDocument ? (
                        <div>
                          <Popup
                            style={{
                              transform: `translate(50%,-50%)`,
                              minWidth: `auto`,
                            }}
                            trigger={
                              <button>
                                {" "}
                                <img
                                  alt='document'
                                  src={iconImage}
                                  width='50'
                                  height='50'
                                />{" "}
                              </button>
                            }
                            position='top center'
                          >
                            <div>
                              {paymentDocument ? (
                                paymentDocument.document ? (
                                  paymentDocument.document.indexOf("pdf") !==
                                  -1 ? (
                                    <div>
                                      <object
                                        data={paymentDocument.document}
                                        type='application/pdf'
                                        style={{
                                          width: `600px`,
                                          height: `500px`,
                                        }}
                                      >
                                        <embed
                                          src={paymentDocument.document}
                                          type='application/pdf'
                                        />
                                      </object>
                                    </div>
                                  ) : (
                                    <img
                                      alt='document.png'
                                      src={paymentDocument.document}
                                      width='600'
                                      height='500'
                                    />
                                  )
                                ) : (
                                  "No Document Available"
                                )
                              ) : (
                                "No Document Available"
                              )}
                            </div>
                          </Popup>
                        </div>
                      ) : (
                        <div>
                          {input.paymentInformation.document.hasError ? (
                            <div
                              className=' input-error-message'
                              style={{ paddingBottom: "10px" }}
                            >
                              {this.state.docErrorMsg}
                            </div>
                          ) : null}

                          <FileBase64Component
                            acceptDoc='.png, .jpg, .jpeg, .pdf, .bmp, .docx'
                            multiple={true}
                            onDone={this.getFiles.bind(this)}
                            disableInput={
                              optionalProps.hasOwnProperty(
                                "receivePaymentForInvoices"
                              ) && optionalProps.receivePaymentForInvoices
                                ? true
                                : false
                            }
                          />
                          <div
                            className=' input-error-message'
                            style={{ color: "darkgrey" }}
                          >
                            Image upload format (.png, .jpg, .jpeg, .bmp, .pdf){" "}
                            <br /> Size must be less than 1 MB <br />
                            <br />{" "}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-actions'>
                {optionalProps.hasOwnProperty("receivePayment") ||
                optionalProps.hasOwnProperty("receivePaymentForInvoices") ? (
                  <a
                    id='form-1'
                    className='cta mg-brand2-color'
                    onClick={(event) =>
                      this.handleMakePaymentClick(event, "Receive Payment")
                    }
                  >
                    {input.paymentInformation.paymentType.value === "CreditCard"
                      ? " Next » "
                      : "Receive Payment"}
                  </a>
                ) : (
                  <a
                    id='form-1'
                    className={`cta ${
                      optionalProps.fromConsumer ? "" : "mg-brand2-color"
                    }`}
                    style={{
                      backgroundColor: optionalProps.hasOwnProperty(
                        "appThemeData"
                      )
                        ? optionalProps.appThemeData.hasOwnProperty(
                            "primary_color"
                          )
                          ? optionalProps.appThemeData.primary_color
                          : ""
                        : "",
                    }}
                    onClick={(event) => {
                      this.handleMakePaymentClick(event, "Accept Payment");
                    }}
                  >
                    {input.paymentInformation.paymentType.value === "CreditCard"
                      ? "Next >>"
                      : "Accept Payment"}
                  </a>
                )}
              </div>
            </div>
          ) : (
            <div>
              {this.state.savedCard.status ? (
                <div>
                  <h4>Credit Card Details</h4>
                  <label style={{ marginBottom: "1em" }}>
                    Card Number: xxxx-xxxx-xxxx-
                    {this.state.savedCard.last4}
                  </label>
                  <a
                    className='text-link'
                    onClick={() => {
                      this.removeCreditCard(this.props.user, {
                        card_id: this.state.savedCard.sourceID,
                      });
                    }}
                  >
                    Remove Card
                  </a>
                </div>
              ) : (
                <div>
                  <div style={style.container}>
                    <div style={style.content}>Name on Card : </div>
                    <div
                      className={`input-container${
                        input.CardInformation.nameOnCard.hasError
                          ? " error"
                          : ""
                      }`}
                    >
                      <input
                        type='text'
                        id='nameOnCard'
                        placeholder='Enter Name'
                        value={input.CardInformation.nameOnCard.value}
                        onChange={(event) =>
                          this.handleInputChange({
                            nameOnCard: {
                              value: event.target.value,
                              hasError: false,
                              empty:
                                event.target.value.length > 0 ? false : true,
                            },
                          })
                        }
                      />
                      {input.CardInformation.nameOnCard.hasError
                        ? this.renderErrorMessage("nameOnCard")
                        : null}
                    </div>
                  </div>
                  <div>
                    <div style={style.container}>
                      <div style={style.content}>Credit Card Number : </div>
                      <div
                        className={`input-container${
                          input.CardInformation.cardNumber.hasError
                            ? " error"
                            : ""
                        }`}
                      >
                        <div style={borderBottom}>
                          <CardNumberElement
                            style={CCstyle}
                            placeholder='1234 5678 1234 5678'
                            placeholderChar={"\u2000"}
                            onChange={(event) =>
                              this.handleInputChange({
                                cardNumber: {
                                  empty: event.empty,
                                  hasError: event.error,
                                },
                              })
                            }
                            elementRef={(element) => (this._element = element)}
                          />
                        </div>
                        {input.CardInformation.cardNumber.hasError
                          ? this.renderErrorMessage("cardNumber")
                          : null}
                      </div>
                    </div>
                    <div style={style.container}>
                      <div style={style.content}>Expiration Date : </div>
                      <div
                        className={`input-container${
                          input.CardInformation.cardExpirationDate.hasError
                            ? " error"
                            : ""
                        }`}
                      >
                        <div style={borderBottom}>
                          <CardExpiryElement
                            style={CCstyle}
                            placeholder='01/20'
                            placeholderChar={"\u2000"}
                            onChange={(event) =>
                              this.handleInputChange({
                                cardExpirationDate: {
                                  empty: event.empty,
                                  hasError: event.error,
                                },
                              })
                            }
                            elementRef={(element) => (this._element = element)}
                          />
                        </div>
                        {input.CardInformation.cardExpirationDate.hasError
                          ? this.renderErrorMessage("cardExpirationDate")
                          : null}
                      </div>
                    </div>
                    <div style={style.container}>
                      <div style={style.content}>CVV : </div>
                      <div
                        className={`input-container${
                          input.CardInformation.cvc.hasError ? " error" : ""
                        }`}
                      >
                        <div style={borderBottom}>
                          <CardCVCElement
                            style={CCstyle}
                            placeholder='***'
                            value={input.CardInformation.cvc.value}
                            onChange={(event) =>
                              this.handleInputChange({
                                cvc: {
                                  empty: event.empty,
                                  hasError: event.error,
                                },
                              })
                            }
                            elementRef={(element) => (this._element = element)}
                          />
                        </div>
                        {input.CardInformation.cvc.hasError
                          ? this.renderErrorMessage("cvc")
                          : null}
                      </div>
                    </div>
                    <div style={style.container}>
                      <div style={style.content}>Billing Zip Code : </div>
                      <div
                        className={`input-container${
                          input.CardInformation.cardZip.hasError ? " error" : ""
                        }`}
                      >
                        <div style={borderBottom}>
                          <PostalCodeElement
                            style={CCstyle}
                            placeholder='12345'
                            value={input.CardInformation.cardZip.value}
                            onChange={(event) =>
                              this.handleInputChange({
                                cardZip: {
                                  empty: event.empty,
                                  hasError: event.error,
                                },
                              })
                            }
                          />
                        </div>
                        {input.CardInformation.cardZip.hasError
                          ? this.renderErrorMessage("cardZip")
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div id='submit-form' className='modal-actions'>
                {!this.state.savedCard.status && (
                  <div style={styles.checkboxDiv}>
                    <input
                      type='checkbox'
                      style={styles.checkbox}
                      checked={
                        this.state.savedCard.status ||
                        this.state.savedCard.tempStatus
                      }
                      onChange={(e) => {
                        this.setState({
                          savedCard: {
                            ...this.state.savedCard,
                            tempStatus: e.target.checked,
                          },
                        });
                      }}
                    />
                    <div style={styles.acceptAgr}>
                      Securely save this payment method for future payments.
                    </div>
                  </div>
                )}
                <br />
                {optionalProps.hasOwnProperty("receivePayment") ||
                optionalProps.hasOwnProperty("receivePaymentForInvoices") ? (
                  <a
                    id='receivePayment'
                    className='cta mg-brand2-color'
                    onClick={(event) =>
                      this.handleSubmit(event, "Receive Payment")
                    }
                  >
                    Receive Payment
                  </a>
                ) : (
                  <a
                    id='acceptPayment'
                    className={`cta ${
                      optionalProps.fromConsumer ? "" : "mg-brand2-color"
                    }`}
                    style={{
                      backgroundColor: optionalProps.hasOwnProperty(
                        "appThemeData"
                      )
                        ? optionalProps.appThemeData.hasOwnProperty(
                            "primary_color"
                          )
                          ? optionalProps.appThemeData.primary_color
                          : ""
                        : "",
                    }}
                    onClick={(event) =>
                      this.handleSubmit(event, "Accept Payment")
                    }
                  >
                    Accept Payment
                  </a>
                )}
              </div>
            </div>
          )}
        </form>
      </Modal>
    );
  }
}

const style = {
  container: { display: "flex" },
  content: { width: "65%", paddingTop: `10px` },
};

export default injectStripe(MakePaymentModal);
