//import companyData from "../assets/styles/theme.json";
import OAuth2 from "fetch-mw-oauth2";
import Cookies from "universal-cookie";
import Emitter from "../utils/event-emitter";

// let api = "http://0548-137-59-64-76.ngrok.io";
let api = process.env.REACT_APP_API_URL;
// let api = 'http://4296-1-23-77-216.ngrok.io' //TO_DO
let clientID = process.env.REACT_APP_OAUTH_CLIENT_ID;
let clientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET;

let oauth2;

export const getBaseUrl = () => {
  return window.location.origin;
  // return "https://mgstage.accountpayonline.com";
};

//If user is logged-In, then below code will work
const cookies = new Cookies();
if (cookies.get("authUser") && cookies.get("isLoggedIn")) {
  let accessT = cookies.get("accessToken", { path: "/", secure: true });
  let refreshT = cookies.get("refreshToken", { path: "/", secure: true });

  if (
    (accessT != "null" && accessT != null && accessT != "") ||
    (refreshT != "null" && refreshT != null && refreshT != "")
  ) {
    oauth2 = new OAuth2(
      {
        clientId: clientID,
        clientSecret: clientSecret, // Optional in some cases
        tokenEndpoint: `${api}/oauth/token?organization_key=${getBaseUrl()}`,
        onAuthError: (error) => {
          if (window.location.pathname !== "/login") {
            Emitter.emit("OAUTH_ERROR", true);
          }
        },
        onTokenUpdate: (token) => {
          cookies.remove("accessToken", { path: "/", secure: true });
          cookies.remove("refreshToken", { path: "/", secure: true });
          cookies.set("accessToken", token.accessToken, {
            path: "/",
            secure: true,
          });
          cookies.set("refreshToken", token.refreshToken, {
            path: "/",
            secure: true,
          });
        },
      },
      {
        accessToken: accessT,
        refreshToken: refreshT,
      }
    );
  } else {
    // window.location.href = "/login";
  }
}

const setHeaders = (user, useAuth, useJSON) => {
  let headerObj = {};
  const cookies = new Cookies();
  let authuser = cookies.get("accessToken", { path: "/", secure: true });
  if (useAuth) {
    if (authuser != undefined && authuser != null && authuser != "") {
      headerObj["Authorization"] = "Bearer " + authuser;
    } else if (user.hasOwnProperty("user_auth_token")) {
      if (user.user_auth_token) {
        headerObj["Authorization"] = "Bearer " + user.user_auth_token;
      }
    }
  }

  if (useJSON) {
    headerObj["Content-Type"] = "application/json";
  }
  if (user != null && user.organization_key) {
    headerObj["Organization-Key"] = user.organization_key;
  } else {
    headerObj["Organization-Key"] = "";
  }
  return headerObj;
};

export const setOauthObj = (payload) => {
  let base_url = window.location.origin;
  // let base_url = "https://mgstage.accountpayonline.com";

  if (
    // (payload.email != "null" && payload.email != null && payload.email != "") ||
    // (payload.password != "null" &&
    //   payload.password != null &&
    //   payload.password != "")
    true
  ) {
    oauth2 = new OAuth2({
      grantType: "password",
      clientId: clientID,
      clientSecret: clientSecret,
      userName: payload.email,
      password: payload.password,
      tokenEndpoint: `${api}/oauth/token?organization_key=${getBaseUrl()}`,
      onTokenUpdate: (token) => {
        cookies.remove("accessToken", { path: "/", secure: true });
        cookies.remove("refreshToken", { path: "/", secure: true });
        cookies.set("accessToken", token.accessToken, {
          path: "/",
          secure: true,
        });
        cookies.set("refreshToken", token.refreshToken, {
          path: "/",
          secure: true,
        });
      },
      onAuthError: (error) => {
        if (window.location.pathname !== "/login") {
          Emitter.emit("OAUTH_ERROR", true);
        }
      },
    });
  } else {
    // window.location.href = "/login";
  }
};

// ---- SESSION CONTROL ----
export const signup = (userCanidate) =>
  fetch(`${api}/users`, {
    method: "POST",
    headers: setHeaders(null, false, true),
    body: JSON.stringify({
      user: {
        ...userCanidate,
      },
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateUser = (user, userUpdate) =>
  oauth2
    .fetch(`${api}/users/${user.user_id}`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        user: {
          ...userUpdate,
        },
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getUserbackend = (user) =>
  oauth2
    .fetch(`${api}/version`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateOnboardingPercentage = (user, percent) =>
  oauth2
    .fetch(
      `${api}/client/org/update_onboard_percent?onboarding_percentage=${percent}`,
      {
        method: "PUT",
        headers: setHeaders(user, true, false),
      }
    )
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const sendInvoicesToCollection = (user, accountId, invoiceList) =>
  oauth2
    .fetch(
      `${api}/client/invoice/placeToCollections?invoice_ids=${invoiceList}&&user_id=${accountId}`,
      {
        method: "POST",
        headers: setHeaders(user, true, false),
      }
    )
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });
export const getRegisterationCode = (payload) =>
  fetch(
    `${api}/users/send_reg_token?org_key=${payload.org_key}&&email=${payload.email}`,
    {
      method: "POST",
      headers: setHeaders(null, false, false),
      // body: JSON.stringify({ ...payload }),
    }
  )
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getLogoColors = (user) =>
  fetch(`${api}/logoColors?org_url=${getBaseUrl()}`, {
    method: "GET",
    // headers: setHeaders(null, true, false),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getLogoColorsForPayment = (key) =>
  fetch(`${api}/logoColors?org_url=${getBaseUrl()}`, {
    method: "GET",
    // headers: setHeaders(null, true, true),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getTestCreds = (user, pathVariable) =>
  oauth2
    .fetch(
      `${api}/client/organization/get_test_creds?full_portal=${pathVariable}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getUser = (user) =>
  oauth2
    .fetch(`${api}/users/${user.user_id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const confirmAccount = (user, smsCode) =>
  oauth2
    .fetch(`${api}/users/${user.user_id}/confirm`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        sms_code: smsCode,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const acceptUserAgreement = (user, accepted) =>
  oauth2
    .fetch(`${api}/users/${user.user_id}/agreement`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        accepted: accepted,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const login = (user) =>
  oauth2
    .fetch(`${api}/sessions`, {
      method: "POST",
      headers: setHeaders(user, false, true),
      body: JSON.stringify({
        session: {
          email: user.email,
          password: user.password,
          organization_key: user.organization_key,
          sub_id: user.sub_id,
          openid_id: user.openid_id,
        },
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      if (error && error.message != "Failed to fetch") {
        return { errors: error.message };
      }
    });
export const ssoLogin = (user) =>
  oauth2
    .fetch(`${api}/sso_session`, {
      method: "POST",
      headers: setHeaders(user, false, true),
      body: JSON.stringify({
        session: {
          email: user.email,
          password: user.password,
          organization_key: user.organization_key,
          sub_id: user.sub_id,
          openid_id: user.openid_id,
        },
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      if (error && error.message != "Failed to fetch") {
        return { errors: error.message };
      }
    });

export const ssoLoginForQB = (user) =>
  fetch(`${api}/sso_session`, {
    method: "POST",
    headers: setHeaders(user, false, true),
    body: JSON.stringify({
      session: {
        email: user.email,
        password: user.password,
        organization_key: user.organization_key,
        sub_id: user.sub_id,
        openid_id: user.openid_id,
      },
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      if (error && error.message != "Failed to fetch") {
        return { errors: error.message };
      }
    });

export const configureApp = (company_data) =>
  oauth2
    .fetch(`${api}/configureApp`, {
      method: "POST",
      headers: setHeaders(null, false, true),
      body: JSON.stringify({
        company_data,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const appThirdPartyConfig = (config_data) =>
  oauth2
    .fetch(`${api}/appThirdPartyConfig`, {
      method: "POST",
      headers: setHeaders(null, false, true),
      body: JSON.stringify({
        config_data,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const isAppConfigure = () =>
  oauth2
    .fetch(`${api}/isAppConfigured`, {
      method: "GET",
      headers: setHeaders(null, false, true),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const isThirdPartyAppConfigured = () =>
  fetch(`${api}/isThirdPartyAppConfigured`, {
    method: "GET",
    headers: setHeaders(null, false, true),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getAppInfo = () =>
  fetch(`${api}/getAppInfo`, {
    method: "GET",
    headers: setHeaders(null, false, true),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getAppConfigInfo = (organization_key) =>
  fetch(`${api}/getAppConfigInfo?org_key=${organization_key}`, {
    method: "GET",
    headers: setHeaders(null, false, true),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const signout = (user) =>
  oauth2
    .fetch(`${api}/sign_out`, {
      method: "DELETE",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const passwordResetRequest = (email, key) =>
  fetch(`${api}/passwords`, {
    method: "POST",
    headers: setHeaders({ organization_key: key }, false, true),
    body: JSON.stringify({
      password: {
        email: email,
      },
    }),
  })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const passwordReset = (user, passwordPayload) =>
  fetch(`${api}/users/${passwordPayload.userID}/password`, {
    method: "PUT",
    headers: setHeaders(null, false, true),
    body: JSON.stringify(passwordPayload),
  })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const validateFileData = (user, payload) => {
  return oauth2
    .fetch(`${api}/client/consumer/validate_data`, {
      method: "POST",
      headers: setHeaders(user, true, false),
      body: payload,
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const importAccountData = (user, payload) => {
  return oauth2
    .fetch(`${api}/client/import_data/accounts`, {
      method: "POST",
      headers: setHeaders(user, true, false),
      body: payload,
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const importInvoiceData = (user, payload) => {
  return oauth2
    .fetch(`${api}/client/import_data/invoices`, {
      method: "POST",
      headers: setHeaders(user, true, false),
      body: payload,
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};
// ---- ADMIN::SEARCH ----

export const adminSearch = (user, context, query) =>
  oauth2
    .fetch(`${api}/admin/search/${context}?q=${query}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const adminOfferSearch = (user, context, offer_type, query) =>
  oauth2
    .fetch(`${api}/admin/search/${context}?type=${offer_type}&q=${query}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ---- ADMIN::ADMINS ----
export const fetchAllAdmins = (user, organization_id) =>
  oauth2
    .fetch(`${api}/admin/admins?organization_id=${organization_id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const DeleteOwner = (user, id) =>
  oauth2
    .fetch(`${api}/admin/owner/delete?id=${id}`, {
      method: "Delete",
      headers: setHeaders(user, true, true),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createAdmin = (user, adminCanidate) =>
  oauth2
    .fetch(`${api}/admin/organization/addAdmin`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...adminCanidate,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const resetAdminPassword = (user, adminId) =>
  oauth2
    .fetch(`${api}/admin/admins/${adminId}/reset_admin_password`, {
      method: "POST",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const deactivateAdmin = (user, adminId) =>
  oauth2
    .fetch(`${api}/admin/admins/${adminId}/deactivate`, {
      method: "POST",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ---- ADMIN::CONSUMERS ----

export const fetchAllConsumers = (user) =>
  oauth2
    .fetch(`${api}/admin/consumers`, { headers: setHeaders(user, true, false) })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchAllPendingAddressConsumers = (user) =>
  oauth2
    .fetch(`${api}/admin/consumers/pendingAddressUser`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchDebtDetail = (user) =>
  oauth2
    .fetch(`${api}/consumer/debtDetailList`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getConsumer = (user, id) =>
  oauth2
    .fetch(`${api}/admin/consumers/${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getViewConsumerConversation = (user, id) =>
  oauth2
    .fetch(`${api}/admin/consumers/${id}/conversation`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getViewConsumerAddressDocument = (user, context, id) =>
  oauth2
    .fetch(`${api}/${context}/consumers/${id}/addressDocument`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const approveAddressDocument = (user, context, id) =>
  oauth2
    .fetch(`${api}/${context}/consumers/${id}/approveAddressDocument`, {
      method: "PUT",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const rejectAddressDocument = (user, context, id) =>
  oauth2
    .fetch(`${api}/${context}/consumers/${id}/rejectAddressDocument`, {
      method: "PUT",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getCleintAdminUser = (user) =>
  oauth2
    .fetch(`${api}/users/${user.user_id}/client_admins`, {
      method: "GET",
      headers: setHeaders(user, true, true),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getConsumerEdit = (user, consumer_id) =>
  oauth2
    .fetch(`${api}/admin/consumers/${consumer_id}/edit`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateConsumer = (user, consumer_id, consumer) =>
  oauth2
    .fetch(`${api}/admin/consumers/${consumer_id}`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        consumer,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createConsumer = (user, consumer) =>
  oauth2
    .fetch(`${api}/admin/consumers/`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        consumer,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createCreditor = (user, creditor) =>
  oauth2
    .fetch(`${api}/creditor/`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        creditor,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const deactivateConsumer = (user, consumer_id) =>
  oauth2
    .fetch(`${api}/admin/consumers/${consumer_id}/deactivate`, {
      method: "POST",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ---- ADMIN::OFFERS ----

export const getPendingOffers = (user) =>
  oauth2
    .fetch(`${api}/admin/offers/`, { headers: setHeaders(user, true, false) })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getOfferList = (user, offer_type) =>
  oauth2
    .fetch(`${api}/admin/offers/getOfferList?offer_type=` + offer_type, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getPendingOffersCount = (user) =>
  oauth2
    .fetch(`${api}/admin/offers/pendingOffersCount`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const pendingActions = (user) =>
  oauth2
    .fetch(`${api}/admin/admins/pending_actions`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getAdminOffer = (user, id) =>
  oauth2
    .fetch(`${api}/admin/offers/${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const adminAcceptOffer = (user, offerId) =>
  oauth2
    .fetch(`${api}/admin/offers/${offerId}/accept`, {
      method: "POST",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const adminCounterOffer = (user, offerId, offer) =>
  oauth2
    .fetch(`${api}/admin/offers/${offerId}/counter`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify(offer),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const adminRejectOffer = (user, offerId) =>
  oauth2
    .fetch(`${api}/admin/offers/${offerId}/reject`, {
      method: "POST",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ---- ADMIN::CREDITORS ----

export const fetchAllCreditors = (user) =>
  oauth2
    .fetch(`${api}/creditors`, { headers: setHeaders(user, true, false) })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ---- ADMIN::CREDITORS ----
export const uploadCSV = (user, csv) =>
  oauth2
    .fetch(`${api}/admin/debts/import`, {
      method: "POST",
      headers: setHeaders(user, true, false),
      body: csv,
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ---- CONSUMER::DEBTS ----
export const fetchAllDebts = (user) =>
  oauth2
    .fetch(`${api}/consumer/debts`, { headers: setHeaders(user, true, false) })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getDebt = (user, id) =>
  oauth2
    .fetch(`${api}/consumer/debts/${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ---- CONSUMER::OFFERS ----
export const getConsumerOffer = (user, id) =>
  oauth2
    .fetch(`${api}/consumer/offers/${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const consumerCreateOffer = (user, debtId, offer, stripeToken) =>
  oauth2
    .fetch(`${api}/consumer/debts/${debtId}/offers`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        offer: {
          ...offer,
        },
        stripe_token: stripeToken,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
// Client Accept Payment
export const getCardInfo = (user, userID) => {
  return oauth2
    .fetch(`${api}/client/card_info?user_id=${userID}`, {
      method: "GET",
      headers: setHeaders(user, true, true),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const addCardInfo = (user, payload, userID) => {
  return oauth2
    .fetch(`${api}/client/add_card?user_id=${userID}`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify(payload),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const removeCardInfo = (user, payload, userID) => {
  return oauth2
    .fetch(`${api}/client/delete_card?user_id=${userID}`, {
      method: "DELETE",
      headers: setHeaders(user, true, true),
      body: JSON.stringify(payload),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

// ---- CONSUMER::PAYMENTS ----
export const getCardPaymentConfirmation = async (intentID) => {
  try {
    const response = await fetch(`${api}/check3ds?charge_id=${intentID}`, {
      method: "GET",
      headers: setHeaders(null, false, true),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("error==========:", error);
  }
};
export const settleAccountPayment = (user, stripeToken, debtID, amount) =>
  oauth2
    .fetch(`${api}/payment`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        debtID: debtID,
        cardToken: stripeToken,
        amount: amount,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ----- CONSUMER::CUSTOMER ID -----
export const createOrUpdateStripeCustomerId = (user, stripeToken) =>
  oauth2
    .fetch(`${api}/consumer/consumerCardInfo`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        cardToken: stripeToken,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getConsumerCardList = (user) =>
  oauth2
    .fetch(`${api}/consumer/cards`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const removeCard = (user, payload) =>
  oauth2
    .fetch(`${api}/consumer/removeCard`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const disconnectBank = (user, payload) =>
  oauth2
    .fetch(`${api}/consumer/payment/disconnectBank`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const consumerAcceptOffer = (user, offerId) =>
  oauth2
    .fetch(`${api}/consumer/offers/${offerId}/accept`, {
      method: "POST",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const consumerCounterOffer = (user, offerId, offer) =>
  oauth2
    .fetch(`${api}/consumer/offers/${offerId}/counter`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify(offer),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const uploadValidationDocument = (user, payload, type) =>
  oauth2
    .fetch(`${api}/client/${type}/document`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getAttachedDocuement = (user, id, type) =>
  oauth2
    .fetch(`${api}/client/${type}/document/file?document_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getDownloadPaymentDocument = (user, id, type) =>
  oauth2
    .fetch(`${api}/client/${type}/document?document_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getAttachedDocuementForConsumer = (user, id, type) =>
  oauth2
    .fetch(`${api}/consumer/${type}/document/file?document_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const updateValidationDocument = (user, payload, type) =>
  oauth2
    .fetch(`${api}/client/${type}/document`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const deleteVerificationDocument = (user, doc_id, type) =>
  oauth2
    .fetch(`${api}/client/${type}/document?document_id=${doc_id}`, {
      method: "DELETE",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getInvoiceDocuments = (user, id) =>
  oauth2
    .fetch(`${api}/client/debts/document?invoice_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getMultiInvoiceDocuments = (user, payload) =>
  oauth2
    .fetch(`${api}/client/debts/multidoclist`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getDebtValidationDocuments = (user, id) =>
  oauth2
    .fetch(`${api}/client/debts/document?debt_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getConsumerDebtPaymentHistory = (user, id) =>
  oauth2
    .fetch(`${api}/consumer/paymentHistory?debt_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const requestValidationDocumentsMailed = (user, id) =>
  oauth2
    .fetch(`${api}/consumer/debts/${id}/request_documentation`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

//------------1023 start ---------------------
export const updateConsumerProfile = (user, id) =>
  oauth2
    .fetch(`${api}/consumer/profile`, {
      method: "POST",
      headers: setHeaders(user, true, false),
      body: JSON.stringify(user),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
//------------1023 end -----------------------

// ---- CONSUMER::HELP FORM ----
export const consumerRequestHelp = (user, formData) =>
  oauth2
    .fetch(`${api}/contact_us`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify(formData),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const consumerGetHelp = (key, formData) =>
  fetch(`${api}/contact_us`, {
    method: "POST",
    headers: setHeaders({ organization_key: key }, false, true),
    body: JSON.stringify(formData),
  })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const marketingContact = (formData) =>
  fetch(`${api}/contact_us`, {
    method: "POST",
    headers: setHeaders(null, false, true),
    body: JSON.stringify(formData),
  })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ----------------------------------------------------------------------------- //

//---- CLIENTS::SEARCH ----

export const clientsSearch = (user, context, query) =>
  oauth2
    .fetch(`${api}/client/search/${context}?q=${query}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientsOfferSearch = (user, role, context, offer_type, query) =>
  oauth2
    .fetch(`${api}/${role}/search/${context}?type=${offer_type}&q=${query}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ---- CLIENTS::ADMIN ----
export const fetchAllClients = (user) =>
  oauth2
    .fetch(`${api}/client/admins`, { headers: setHeaders(user, true, false) })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createClients = (user, clientsCanidate) =>
  oauth2
    .fetch(`${api}/client/admins/`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        user: {
          ...clientsCanidate,
        },
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const deactivateClients = (user, clientsId) =>
  oauth2
    .fetch(`${api}/client/admins/${clientsId}/deactivate`, {
      method: "POST",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createClientAdmin = (user, adminCanidate) =>
  oauth2
    .fetch(`${api}/client/createClientAdmin/`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        user: {
          ...adminCanidate,
        },
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createEmployee = (
  user,
  adminCandidate,
  consumer_ids,
  is_all_client,
  save_and_send
) =>
  oauth2
    .fetch(`${api}/client/employee/create`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        user: {
          ...adminCandidate,
        },
        consumer_ids,
        is_all_client,
        save_and_send,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const updateEmployee = (
  user,
  adminCandidate,
  consumer_ids,
  is_all_client,
  save_and_send
) =>
  oauth2
    .fetch(`${api}/client/employee/update`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        user: {
          ...adminCandidate,
        },
        consumer_ids,
        is_all_client,
        save_and_send,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchEmployeeDetails = (user, id) =>
  oauth2
    .fetch(`${api}/users/details?user_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getSentMails = (user, page) =>
  oauth2
    .fetch(`${api}/client//mandrill/getSentMailList?page=${page}&all=${true}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getSubAccountDetails = (user) =>
  oauth2
    .fetch(`${api}/client/mandrill/getSubaccountDetail`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
// ---- CLIENTS::CONSUMERS ----

export const fetchAllClientsConsumers = (user) =>
  oauth2
    .fetch(`${api}/admin/client/consumers`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchAllCompanyClients = (user, offset) =>
  oauth2
    .fetch(`${api}/client/consumers?offset=${offset}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchAllEmployees = (user, offset, searchText) =>
  searchText == null || searchText == ""
    ? oauth2
        .fetch(`${api}/client/employee/list?offset=${offset}`, {
          headers: setHeaders(user, true, false),
        })
        .then((response) => response.json())
        .then(function (data) {
          return data;
        })
        .catch((error) => {
          console.error("error==========:", error);
        })
    : oauth2
        .fetch(`${api}/client/employee/list?offset=${offset}&q=${searchText}`, {
          headers: setHeaders(user, true, false),
        })
        .then((response) => response.json())
        .then(function (data) {
          return data;
        })
        .catch((error) => {
          console.error("error==========:", error);
        });

export const fetchAllClientsPendingAddressConsumers = (user) =>
  oauth2
    .fetch(`${api}/client/consumers/pendingAddressUser`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getClientsConsumer = (user, id, order, offset, filter_by) =>
  oauth2
    .fetch(
      `${api}/client/consumers/${id}?page=${offset}&filter_by=${filter_by}`,
      {
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getViewClientsConsumerConversation = (user, id) =>
  oauth2
    .fetch(`${api}/client/consumers/${id}/conversation`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getClientsConsumerEdit = (user, consumer_id) =>
  oauth2
    .fetch(`${api}/client/consumers/${consumer_id}/edit`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateClientsConsumer = (user, consumer_id, consumer) =>
  oauth2
    .fetch(`${api}/client/consumers/${consumer_id}`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        consumer,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const addConsumerDebt = (user, consumer_id, consumer) =>
  oauth2
    .fetch(`${api}/client/debts/create/`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        consumer,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createClientsConsumer = (user, consumer) =>
  oauth2
    .fetch(`${api}/client/consumers`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        consumer,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const deactivateClientsConsumer = (user, consumer_id) =>
  oauth2
    .fetch(`${api}/client/consumers/${consumer_id}/deactivate`, {
      method: "POST",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const deleteClientsConsumer = (user, consumer_ids) =>
  oauth2
    .fetch(`${api}/client/delete?ids=${[consumer_ids]}`, {
      method: "DELETE",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ---- CLIENTS::OFFERS ----

export const getClientsOfferList = (user, context, offer_type) =>
  oauth2
    .fetch(`${api}/${context}/offers/getOfferList?offer_type=` + offer_type, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getPendingClientsOffersCount = (user) =>
  oauth2
    .fetch(`${api}/client/offers/pendingOffersCount`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientsAcceptOffer = (user, context, offerId, note) =>
  oauth2
    .fetch(`${api}/${context}/offers/${offerId}/accept`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ description: note }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientsCounterOffer = (user, context, offerId, offer) =>
  oauth2
    .fetch(`${api}/${context}/offers/${offerId}/counter`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify(offer),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientsRejectOffer = (user, context, offerId, note) =>
  oauth2
    .fetch(`${api}/${context}/offers/${offerId}/reject`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ description: note }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const companyAdminSearch = (user, context, query) =>
  oauth2
    .fetch(`${api}/admin/client/search/${context}?q=${query}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const companySearch = (user, context, query) =>
  oauth2
    .fetch(`${api}/client/search/${context}?q=${query}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createOrganization = (user, organization) =>
  oauth2
    .fetch(`${api}/admin/organizations/`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        organization,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ---- CLIENT::SEARCH ----

export const clientSearch = (
  user,
  context,
  query,
  offset,
  lobSearch,
  customer_type,
  sort_by,
  order
) =>
  oauth2
    .fetch(
      `${api}/client/search/${context}?q=${query}${
        lobSearch ? `&lobSearch=${lobSearch}` : ""
      }&customer_type=${customer_type}&offset=${offset}${
        sort_by ? `&sort_by=${sort_by}` : ""
      }${order ? `&order=${order}` : ""}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// ---- CONSUMER::SEARCH ----

export const consumerSearch = (user, text, id, is_open) =>
  oauth2
    .fetch(
      `${api}/consumer/invoice/search?text=${text}&user_id=${id}&is_open=${is_open}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientOfferSearch = (user, context, offer_type, query) =>
  oauth2
    .fetch(`${api}/client/search/${context}?type=${offer_type}&q=${query}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const uploadClientCSV = (user, csv) =>
  oauth2
    .fetch(`${api}/client/debts/import`, {
      method: "POST",
      headers: setHeaders(user, true, false),
      body: csv,
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const paymentByChequeOrCash = (user, paymentInfo) =>
  oauth2
    .fetch(`${api}/client/payment`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        paymentInfo,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const receivePaymentByChequeOrCash = (user, paymentInfo, id) =>
  oauth2
    .fetch(`${api}/client/payment_plans/${id}/receive_payment`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        paymentInfo,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const receiveMultipleInvoicesPayment = (user, paymentInfo, id) =>
  oauth2
    .fetch(`${api}/client/multiple_invoices_payment`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        paymentInfo,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const acceptClientsConsumerOffer = (user, consumer_ids) =>
  oauth2
    .fetch(`${api}/client/accept_multiple_offers`, {
      method: "POST",
      headers: setHeaders(user, true, false),
      body: JSON.stringify({
        consumer_ids,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const rejectClientsConsumerOffer = (user, consumer_ids) =>
  oauth2
    .fetch(`${api}/client/reject_multiple_offers`, {
      method: "POST",
      headers: setHeaders(user, true, false),
      body: JSON.stringify({
        consumer_ids,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const updateDefaultService = (user, payload) => {
  return oauth2
    .fetch(`${api}/client/default_service`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });
};
export const updateClient = (client, clientUpdate) =>
  oauth2
    .fetch(`${api}/client/update_user`, {
      method: "PUT",
      headers: setHeaders(client, true, true),
      body: JSON.stringify({
        client: {
          ...clientUpdate,
        },
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientUpdateEmployee = (client, empId, clientUpdateEmployee) =>
  oauth2
    .fetch(`${api}/client/employee/update?id=${empId}`, {
      method: "POST",
      headers: setHeaders(client, true, true),
      body: JSON.stringify({
        employee: {
          ...clientUpdateEmployee,
        },
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const skipPaymetSetting = (client, skip_payment_value) =>
  oauth2
    .fetch(`${api}/client/skipOfferPayment`, {
      method: "POST",
      headers: setHeaders(client, true, true),
      body: JSON.stringify({
        ...skip_payment_value,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const skipPaymetForConsumer = (user, info) =>
  oauth2
    .fetch(`${api}/consumer/skip_payment`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ info }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const saveNegotiationThreshold = (client, negotiationData) =>
  oauth2
    .fetch(`${api}/client/threshold/create_multiple`, {
      method: "POST",
      headers: setHeaders(client, true, true),
      body: JSON.stringify({
        ...negotiationData,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchSkipPaymentData = (user) =>
  oauth2
    .fetch(`${api}/client/get_skip_payment`, {
      headers: setHeaders(user, true, true),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchPaymentHistory = (
  user,
  context,
  paymentType,
  offset,
  searchText,
  sort_key,
  sort_by
) =>
  oauth2
    .fetch(
      `${api}/${context}/paymentHistory?type=${paymentType}&offset=${offset}&order=${sort_by}&sort_by=${sort_key}&searchText=${searchText}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchLobHistory = (user, offset, searchText, query) =>
  oauth2
    .fetch(
      `${api}/client/lob/list?offset=${offset}&searchText=${searchText}&${query}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchNotificationHistory = (user, page, filter_by) =>
  oauth2
    .fetch(`${api}/client/notifications?page=${page}&filter_by=${filter_by}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateNotificationHistory = (user, id) =>
  oauth2
    .fetch(`${api}/client/notifications/${id}`, {
      method: "PUT",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const sendMailByLob = (user, mailData) =>
  oauth2
    .fetch(`${api}/client/lob/sendLetter`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...mailData,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchChatHistory = (user, offset) =>
  oauth2
    .fetch(`${api}/client/intercom/conversation?offset=${offset}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchConsumerChatHistory = (user, userId) =>
  oauth2
    .fetch(`${api}/client/intercom/user/conversation?user_id=${userId}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchMailchimpCampaignHistory = (user, offset) =>
  oauth2
    .fetch(`${api}/client/mailchimps/getAllCampaignList?offset=${offset}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchMailchimpCampaignMemberHistory = (
  user,
  workflow_id,
  offset
) =>
  oauth2
    .fetch(
      `${api}/client/mailchimps/getAllCampaignMembers?workflow_id=${workflow_id}&?offset=${offset}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchSkipTraceData = (user, offset) =>
  oauth2
    .fetch(`${api}/client/skip_traces/getSkipTraceList?offset=${offset}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const uploadSkipTraceCSV = (user, csv) =>
  oauth2
    .fetch(`${api}/client/skip_traces/import`, {
      method: "POST",
      headers: setHeaders(user, true, false),
      body: csv,
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientSkipTraceUserSearch = (user, query) =>
  oauth2
    .fetch(`${api}/client/skip_traces/search?q=${query}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const adjustmentPayment = (user, paymentInfo) =>
  oauth2
    .fetch(`${api}/client/adjustmentPayment`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        paymentInfo,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getLobTemplate = (user, type) => {
  return oauth2
    .fetch(`${api}/client/lob/templateListFromDb?type=${type}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const getMergeVariablesForLobTemplate = (user, id) =>
  oauth2
    .fetch(`${api}/client/lob/template_vars?id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getMandrillTemplate = (user, tempSlug, type) =>
  oauth2
    .fetch(
      `${api}/client/mandrill/getTemplateList?template=${tempSlug}&type=${type}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const getPaymentPlansTemplate = (user) =>
  oauth2
    .fetch(`${api}/client/payment_plans/templates`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getMergeVariables = (user, name) =>
  oauth2
    .fetch(`${api}/client//mandrill/getMergeVariable?name=${name}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const sendMandrillTemplate = (user, payload, userId) =>
  oauth2
    .fetch(`${api}/client/mandrill/sendMailManually?user_id=${userId}`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });
export const freeFormEmail = (user, payload) =>
  oauth2
    .fetch(`${api}/client/invoice/freeFormEmail`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const sendMultipleMandrillTemplate = (user, payload, userId) =>
  oauth2
    .fetch(`${api}/client/mandrill/sendMultipleInvoices?user_id=${userId}`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const sendPaymentPlanEmail = (user, payload) =>
  oauth2
    .fetch(`${api}/client/payment_plans/send_mail`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const registrationCodeVerification = (token) =>
  fetch(`${api}/client/consumer/validate`, {
    method: "POST",
    headers: setHeaders(null, false, true),
    body: JSON.stringify({
      token: token,
    }),
  })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateNewRegisterUser = (userUpdate) =>
  fetch(`${api}/users/update_consumer`, {
    method: "PUT",
    headers: setHeaders(null, false, true),
    body: JSON.stringify(userUpdate),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const sendOTP = (data) =>
  fetch(`${api}/users/send_registration_otp_code`, {
    method: "PUT",
    headers: setHeaders(null, false, true),
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const verifyOTP = (data) =>
  fetch(`${api}/users/veriy_otp`, {
    method: "PUT",
    headers: setHeaders(null, false, true),
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateUserPassword = (data) =>
  fetch(`${api}/users/update_user_password`, {
    method: "PUT",
    headers: setHeaders(null, false, true),
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const startAutomatedMails = (user, query) =>
  oauth2
    .fetch(`${api}/client/consumer/startAutomatedMails?user_id=${query}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const addOrganization = (user, organization) =>
  oauth2
    .fetch(`${api}/admin/organization/create`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify(organization),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchAllOrganizations = (user, page, searchText) =>
  oauth2
    .fetch(
      `${api}/admin/organization/list?page=${page}&search_text=${searchText}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchOrganizationDetails = (user, id) =>
  oauth2
    .fetch(`${api}/admin/organization/detail?id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateOrganization = (user, payload) =>
  oauth2
    .fetch(`${api}/admin/organization/updateOrg`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateInternalContact = (user, payload) =>
  oauth2
    .fetch(`${api}/client/employee/update_internal_contact`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const updateGoodQuantityPermission = (user, payload) =>
  oauth2
    .fetch(`${api}/client/employee/update_permission`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateClientPassword = (user, password) =>
  oauth2
    .fetch(`${api}/client/updatePassword`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        password: password,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

//employee
export const updateEmployeePassword = (user, password) =>
  oauth2
    .fetch(`${api}/employee/updatePassword`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        password: password,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchAllClientsEmployee = (user) =>
  oauth2
    .fetch(`${api}/client/employee/listAll`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchAllUnassignedConsumers = (user, employee_id) =>
  oauth2
    .fetch(`${api}/client/consumer/unassigned?employee_id=${employee_id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const assignEmployeeToConsumer = (user, payload) =>
  oauth2
    .fetch(`${api}/client/employee/assignToConsumers`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        employee_id: payload.employee_id,
        user_ids: payload.user_ids,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchAllConsumerForEmployee = (user, offset) =>
  oauth2
    .fetch(`${api}/employee/accounts?offset=${offset}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const employeeCreateConsumer = (user, consumer) =>
  oauth2
    .fetch(`${api}/employee/consumers`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        consumer,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const employeeSearchConsumers = (user, query, offset, lobSearch) =>
  oauth2
    .fetch(
      `${api}/employee/searchConsumers?q=${query}&lobSearch=${
        lobSearch ? lobSearch : false
      }`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const validateQB = (user) =>
  oauth2
    .fetch(`${api}/qb`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const checkAuthStatus = (user, cancel_sync = false) =>
  oauth2
    .fetch(`${api}/qb/checkAuthStatus?cancel_sync=${cancel_sync}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getQBCustomer = (user) =>
  oauth2
    .fetch(`${api}/qb/customer`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateQbSettings = (user, settings) =>
  oauth2
    .fetch(`${api}/qb/update_qb_setting`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...settings,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getQbSetting = (user) =>
  oauth2
    .fetch(`${api}/qb/qb_setting`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getQbItemCount = (user) =>
  oauth2
    .fetch(`${api}/qb/items_to_sync_count`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getTasksList = (user) =>
  oauth2
    .fetch(`${api}/client/setting_tasks/getList`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getPaymentPlPlanTaskList = (user) =>
  oauth2
    .fetch(`${api}/client/payment_plan_task_config/list`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateTaskSetting = (user, settings) =>
  oauth2
    .fetch(`${api}/client/setting_tasks/updateSetting`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...settings,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const paymentPlanTaskUpdate = (user, settings) =>
  oauth2
    .fetch(`${api}/client/payment_plan_task_config/update`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...settings,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getSelectedContacts = (user, id, offset) =>
  oauth2
    .fetch(
      `${api}/client/setting_tasks/getSelectedContacts?task_id=${id}&offset=${offset}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const getSelectedContactsunassigned = (user, id, offset) =>
  oauth2
    .fetch(`${api}/client/consumer/unassigned`, {
      method: "GET",
      headers: setHeaders(user.user_id, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getSettingsTasks = (user) =>
  oauth2
    .fetch(`${api}/client/setting_tasks/getTasks`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getInvoiceTasks = (user, id, page, sortBy, orderBy) =>
  oauth2
    .fetch(
      `${api}/client/setting_tasks/getInvoiceTasks?task_id=${id}&page=${page}&sort_by=${sortBy}&order_by=${orderBy}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const dashobardArReport = (user, sort_by, sort_key) => {
  return oauth2
    .fetch(
      `${api}/dashboard/arAgingReport?sort_by=${sort_by}&order=${sort_key}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const dashboardUpcomingEvents = (user) =>
  oauth2
    .fetch(`${api}/dashboard/upcoming_events`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const ArAgingReport = (user) =>
  oauth2
    .fetch(`${api}/dashboard/arAgingReport`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getAcpAddCeiReport = (user) =>
  oauth2
    .fetch(`${api}/dashboard/getAcpAddCeiReport`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getMostAtRiskReport = (user) =>
  oauth2
    .fetch(`${api}/dashboard/getMostAtRiskReport`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getSubscriptionReport = (user, key) =>
  oauth2
    .fetch(
      `${api}/client/reports/subscription_report_list?search_text=${key}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// item_names for only inventory consumption reports
export const arAgingSummaryList = (user, id, from, to, item_ids) =>
  oauth2
    .fetch(
      `${api}/client/reports/ar_aging_summary_list_for_api?master_report_id=${id}&from_date=${from}&to_date=${to}&item_ids=${item_ids}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchAllClientsForTask = () =>
  oauth2
    .fetch(`${api}/client/fetchAllClientsForTask`, {
      method: "GET",
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// item_names params is used for inventory consumption report
export const downloadReport = (user, reportId, type, from, to, item_ids) =>
  oauth2
    .fetch(
      `${api}/client/reports/download_report?master_report_id=${reportId}&report_format=${type}&from_date=${from}&to_date=${to}&item_ids=${item_ids}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getAccountActivities = (user, id) =>
  oauth2
    .fetch(`${api}/client/consumer/activities?user_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const searchInvoiceId = (user, id) =>
  oauth2
    .fetch(`${api}/client/invoice/search?text=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const searchInvoiceForConsumer = (user, id) =>
  oauth2
    .fetch(`${api}/consumer/search_invoice?text=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getInvoiceList = (user, due_date, order, offset, filter_by) =>
  oauth2
    .fetch(
      `${api}/client/invoice/list?sort_by=${due_date}&order=${order}&page=${offset}&filter_by=${filter_by}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getInvoiceDetails = (user, id) =>
  oauth2
    .fetch(`${api}/client/invoice/detail?id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getSubscriptionList = (user, id) =>
  oauth2
    .fetch(`${api}/client/subscriptions/get_subscription_list`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createSubscription = (user, payload) =>
  oauth2
    .fetch(`${api}/client/subscriptions/create_new_subscription`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateSubscription = (user, payload) =>
  oauth2
    .fetch(`${api}/client/subscriptions/update_subscription`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const addInvoiceNote = (user, payload) =>
  oauth2
    .fetch(`${api}/client/invoice_notes`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const addAccountNote = (user, payload, accountId) => {
  return oauth2
    .fetch(`${api}/client/consumers/${accountId}/add_note`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const getAccountCommunication = (user, accountId, type) => {
  return oauth2
    .fetch(`${api}/client/consumers/${accountId}/communications?type=${type}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const deleteSubscription = (user, payload) =>
  oauth2
    .fetch(`${api}/client/subscriptions/delete_subscription`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getInvoiceHistory = (user, id) =>
  oauth2
    .fetch(`${api}/client/invoice/history?id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const reImportInvoice = (user, id) =>
  oauth2
    .fetch(`${api}/qb/invoice?id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const sendInvoice = (user, id) =>
  oauth2
    .fetch(`${api}/client/mandrill/send_invoice?invoice_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getRefundList = (user, id) =>
  oauth2
    .fetch(`${api}/client/refund/payments?invoice_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getUnappliedPayments = (user, id, offset) => {
  return oauth2
    .fetch(`${api}/client/consumers/${id}/payments?offset=${offset}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const getUnappliedPaymentList = (user, id, offset) => {
  return oauth2
    .fetch(`${api}/client/consumers/${id}/payments?filter_by=unapplied`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

//cancel refund payment
export const getCancelRefundList = (user, id) =>
  oauth2
    .fetch(`${api}/client/payment/payments_to_cancel?invoice_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const cancelRefundPayment = (user, payload) =>
  oauth2
    .fetch(`${api}/client/payment/cancel`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

//consumer invoice
export const fetchAllConsumerInvoices = (user, page, is_open) =>
  oauth2
    .fetch(`${api}/consumer/invoice/pageData?page=${page}&is_open=${is_open}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const consumerInvoicesPagination = (user, page, is_open, filter_by) =>
  oauth2
    .fetch(
      `${api}/consumer/invoice/list?page=${page}&is_open=${is_open}&filter_by=${filter_by}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const fetchConsumerInvoiceDetails = (user, id) =>
  oauth2
    .fetch(`${api}/consumer/invoice/detail?id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getConsumerDetails = (user) =>
  oauth2
    .fetch(`${api}/consumer/profileData`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updatePersonalInfo = (user, payload) =>
  oauth2
    .fetch(`${api}/consumer/updatePersonalInfo`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateCompanyInfo = (user, payload) =>
  oauth2
    .fetch(`${api}/consumer/updateCompanyInfo`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const disconnectQB = (user) =>
  oauth2
    .fetch(`${api}/qb/disconnectAccount`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getInvoiceForExpressPayment = (key, number, amount) =>
  fetch(
    `${api}/expressPayment/getInvoice?invoice_number=${number}&invoice_amount=${amount}&within_invoice_detail=true`,
    {
      method: "GET",
      headers: setHeaders({ organization_key: key }, true, true),
    }
  )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const sendPaymentReceipt = (invoice_number, amount, email) =>
  fetch(
    `${api}/expressPayment/send_payment_receipt?invoice_number=${invoice_number}&amount=${amount}&email=${email}`,
    {
      method: "GET",
      headers: setHeaders(null, false, false),
    }
  )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const sendRegistrationLink = (user, userId) =>
  oauth2
    .fetch(`${api}/users/send_registration_link?user_id=${userId}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateConsumerBillingDetails = (user, consumer) =>
  oauth2
    .fetch(`${api}/client/consumer/update_billing_details`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...consumer,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const consumerMakePayment = (user, payload) => {
  return oauth2
    .fetch(`${api}/consumer/make_payment`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const consumerMakePaymentForPlans = (user, payload) =>
  oauth2
    .fetch(`${api}/consumer/payment_plan/receive_payment`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

//pay Invoices
export const payMultipleInvoices = (user, payload) => {
  return oauth2
    .fetch(`${api}/consumer/make_payment_for_invoices`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const updateSubscriptionStatus = (user, data) =>
  oauth2
    .fetch(`${api}/client/subscriptions/update_status`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...data,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getAdminProfilePopupData = (user) =>
  oauth2
    .fetch(`${api}/admin/profile/popupData`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const adminCreateProfile = (user, payload) =>
  oauth2
    .fetch(`${api}/admin/profile/create`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const adminUpdateProfile = (user, payload) =>
  oauth2
    .fetch(`${api}/admin/profile/update`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getAdminProfileList = (user, offset) =>
  oauth2
    .fetch(`${api}/admin/profile/list?offset=${offset}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const deleteAdminProfile = (user, id) =>
  oauth2
    .fetch(`${api}/admin//profile/delete?id=${id}`, {
      method: "DELETE",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getOrganizationTaskList = (user, id) =>
  oauth2
    .fetch(`${api}/admin/task/list?organization_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getTemplateMergeVar = (user, id, slugName) =>
  oauth2
    .fetch(
      `${api}/admin/task/templateMergeVariables?organization_id=${id}&name=${slugName}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getTemplateMergeVarForClients = (user, slugName) =>
  oauth2
    .fetch(`${api}/client/task/templateMergeVariables?name=${slugName}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const previewTaskTemplate = (user, payload) =>
  oauth2
    .fetch(`${api}/admin/task/previewTemplate`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getOrganizationTaskProfileTemp = (user, id) =>
  oauth2
    .fetch(`${api}/admin/task/profileTemplates?organization_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const orgTemplatesAssigned = (user, id) =>
  oauth2
    .fetch(
      `${api}/admin/organization/templatesAssigned?organization_id=${id}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const adminAssignTaskTemplates = (user, payload) =>
  oauth2
    .fetch(`${api}/admin/task/assignTemplates`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateAssignTemplates = (user, payload) =>
  oauth2
    .fetch(`${api}/admin/organization/AssignTemplate`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getDocumentType = (user) =>
  oauth2
    .fetch(`${api}/client/document/types`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getPreviewTemplate = (user, payload) =>
  oauth2
    .fetch(`${api}/client/mandrill/preview`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const unAssignTemplates = (user, payload) =>
  oauth2
    .fetch(`${api}/admin/task/unassignTemplate`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getContactSettings = (user, id) =>
  oauth2
    .fetch(`${api}/client/contactDetails?user_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateContactEmails = (user, payload) =>
  oauth2
    .fetch(`${api}/client/contactDetails/create`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getContactLetter = (user, id) =>
  oauth2
    .fetch(`${api}/client/address?user_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateContactLetter = (user, payload) =>
  oauth2
    .fetch(`${api}/client/address/create`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const enablePayment = (user) =>
  oauth2
    .fetch(`${api}/client/stripeConnects/enablePayment`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getStripeConnectDetails = (user) =>
  oauth2
    .fetch(`${api}/client/stripeConnects/detail`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getExpressAttachedDocuement = (id) =>
  fetch(`${api}/expressPayment/invoiceDocument?document_id=${id}`, {
    method: "GET",
    headers: setHeaders("", false, false),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updatePaymentOption = (user, payload) =>
  oauth2
    .fetch(`${api}/admin/organization/update_payment_option`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getPaymentPreferences = (user) =>
  oauth2
    .fetch(`${api}/client/paymentPreferences/getPreferences`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updatePaymentPreferences = (user, payload) =>
  oauth2
    .fetch(`${api}/client/paymentPreferences/updatePreferences`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getStripeDashboardLink = (user) =>
  oauth2
    .fetch(`${api}/client/stripeConnects/dashboard`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const startExpressPayment = (user, key) =>
  oauth2
    .fetch(`${api}/expressPayment/start_payment`, {
      method: "GET",
      headers: setHeaders({ organization_key: key }, true, true),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getMergeVariablesList = (user, id) =>
  oauth2
    .fetch(`${api}/admin/mergeVariables/list?organization_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateMergeVariables = (user, payload) =>
  oauth2
    .fetch(`${api}/admin/mergeVariables/update`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getPlaidLinkToken = (user, id) =>
  oauth2
    .fetch(`${api}/consumer/payment/plaidLinkToken?invoice_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getPlaidStatus = (user) =>
  oauth2
    .fetch(`${api}/consumer/payment/plaidStatus`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createPlaidPayment = (user, payload) =>
  oauth2
    .fetch(`${api}/consumer/payment/createPlaidPayment`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const sendPlaidData = (user, payload) =>
  oauth2
    .fetch(`${api}/consumer/payment/plaidExchangeToken`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const consumerLinkAccessToken = (user) => {
  return oauth2
    .fetch(`${api}/consumer/payment/linkAccessToken`, {
      method: "GET",
      headers: setHeaders(user, true, true),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};

export const updateMergeVarLogo = (user, id, payload) =>
  oauth2
    .fetch(`${api}/admin/mergeVariables/uploadLogo?organization_id=${id}`, {
      method: "POST",
      headers: setHeaders(user, true, false),
      body: payload,
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

//express payment plaid flow without auth header
export const expressPlaidStatus = (id, key) =>
  fetch(`${api}/expressPayment/plaidStatus?invoice_id=${id}`, {
    method: "GET",
    headers: setHeaders({ organization_key: key }, true, true),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const expressPlaidLinkToken = (id, key) =>
  fetch(`${api}/expressPayment/plaidLinkToken?invoice_id=${id}`, {
    method: "GET",
    headers: setHeaders({ organization_key: key }, true, true),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const expressSendPlaidData = (payload, key) =>
  fetch(`${api}/expressPayment/plaidExchangeToken`, {
    method: "POST",
    headers: setHeaders({ organization_key: key }, true, true),
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const expressCreatePlaidPayment = (payload, key) =>
  fetch(`${api}/expressPayment/createPlaidPayment`, {
    method: "POST",
    headers: setHeaders({ organization_key: key }, true, true),
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const expressLinkAccessToken = (key, invoiceId) =>
  fetch(`${api}/expressPayment/plaidtokenbyaccesstok?invoice_id=${invoiceId}`, {
    method: "GET",
    headers: setHeaders({ organization_key: key }, true, true),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getConsumerPaymentDetails = (user) =>
  oauth2
    .fetch(`${api}/consumer/payment/details`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getExpressPaymentDetails = (id, key) =>
  fetch(`${api}/expressPayment/details?invoice_id=${id}`, {
    method: "GET",
    headers: setHeaders({ organization_key: key }, true, true),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

//express payment open apis for add/remove card //TO_DO
export const addCreaditCardFromExpress = (payload, key) =>
  fetch(`${api}/expressPayment/addCard`, {
    method: "POST",
    headers: setHeaders({ organization_key: key }, true, true),
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const expressRemoveCard = (payload, key) =>
  fetch(`${api}/expressPayment/removeCard`, {
    method: "POST",
    headers: setHeaders({ organization_key: key }, true, true),
    body: JSON.stringify({ ...payload }),
  })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const expressDisconnectBank = (payload, key) =>
  fetch(`${api}/expressPayment/disconnectBank`, {
    method: "POST",
    headers: setHeaders({ organization_key: key }, true, true),
    body: JSON.stringify({ ...payload }),
  })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const expressMakePayment = (payload, key) =>
  fetch(`${api}/expressPayment/makePayment`, {
    method: "POST",
    headers: setHeaders({ organization_key: key }, true, true),
    body: JSON.stringify({ ...payload }),
  })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

//Add incoice apis
export const clientAddServices = (user, payload) =>
  oauth2
    .fetch(`${api}/client/services`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getServiceAccountList = (user) =>
  oauth2
    .fetch(`${api}/client/services/account_list`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getInvoiceRefundData = (user, id) =>
  oauth2
    .fetch(`${api}/client/refund/refund_data?invoice_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const invoiceRefundPayment = (user, payload) =>
  oauth2
    .fetch(`${api}/client/refund/payment`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getClientServices = (user, page, type) =>
  oauth2
    .fetch(`${api}/client/services?page=${page}&type=${type}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const invoiceClientSearch = (user, search) =>
  oauth2
    .fetch(`${api}/client/invoice/searchUser?query=${search}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientServicesSearch = (user, search) =>
  oauth2
    .fetch(`${api}/client/services/search?query=${search}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const creditTermsSearch = (user, search) =>
  oauth2
    .fetch(`${api}/client/terms/search_term?query=${search}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const addInvoice = (user, payload) =>
  oauth2
    .fetch(`${api}/client/invoice/create`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getInvoiceNumber = (user) =>
  oauth2
    .fetch(`${api}/client/invoice/getCreateData`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientUpdateServices = (user, payload) =>
  oauth2
    .fetch(`${api}/client/services/${payload.id}`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientPreviewInvoice = (user, payload) =>
  oauth2
    .fetch(`${api}/client/invoice/preview`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientDeletePaymentPlan = (user, id) => {
  return oauth2
    .fetch(`${api}/client/payment_plans/${id}`, {
      method: "DELETE",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
};
export const clientDeleteInvoice = (user, payload) =>
  oauth2
    .fetch(`${api}/client/invoice/void`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const deleteInvoice = (user, invoiceId) =>
  oauth2
    .fetch(`${api}/client/invoice/delete?invoice_id=${invoiceId}`, {
      method: "DELETE",
      headers: setHeaders(user, true, true),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getTermsListInSettings = (user) =>
  oauth2
    .fetch(`${api}/client/terms/terms_list`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getAccountDaysPastDue = (user) =>
  oauth2
    .fetch(`${api}/client/org/acc_elg_vars`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientCreateTermsInSettings = (user, payload) =>
  oauth2
    .fetch(`${api}/client/terms/create_terms`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientUpdateTermsInSettings = (user, payload) =>
  oauth2
    .fetch(`${api}/client/terms/update_terms`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientUpdateIndividualTermsInSettings = (
  user,
  accountId,
  payload
) =>
  oauth2
    .fetch(`${api}/client/consumers/${accountId}/update_term`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getMiscellaneousInSettings = (user) =>
  oauth2
    .fetch(`${api}/client/miscellaneous/getMiscellaneous`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getConsumerMiscellaneousInSettings = (user) =>
  oauth2
    .fetch(`${api}/consumer/miscellaneous`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getLetterMergeVariables = (user, invoice_id) =>
  oauth2
    .fetch(
      `${api}/client/invoice/letterMergeVariables?invoice_id=${invoice_id}`,
      {
        method: "GET",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientUpdateMiscellaneousInSettings = (user, payload) =>
  oauth2
    .fetch(`${api}/client/miscellaneous/update_miscellaneous`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientUpdateAccountNumber = (user, payload, data) =>
  oauth2
    .fetch(
      `${api}/users/${data.id}/update_account_no?account_number=${data.account_number}`,
      {
        method: "PUT",
        headers: setHeaders(user, true, true),
        body: JSON.stringify({
          ...payload,
        }),
      }
    )
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getSuperGoLiveDate = (user, id) =>
  oauth2
    .fetch(`${api}/admin/organization/get_live_date?organization_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateSuperGoLiveDate = (user, payload) =>
  oauth2
    .fetch(`${api}/admin/organization/update_live_date`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getSsoGoLiveDate = (user, orgUrl) =>
  oauth2
    .fetch(`${api}/client/organization/get_live_date?org_url=${orgUrl}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateSsoGoLiveDate = (user, payload) =>
  oauth2
    .fetch(`${api}/client/organization/update_live_date`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getOpenInvoicesList = (user, id, isCollection = false) =>
  oauth2
    .fetch(
      `${api}/client/invoice/open_invoices?user_id=${id}&&is_collection=${isCollection}`,
      {
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const getPaymentPlanInvoiceList = (user, id) =>
  oauth2
    .fetch(`${api}/client/invoice/payplaninvoices?user_id=${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getPaymentPlanList = (user, id) =>
  oauth2
    .fetch(`${api}/client/payment_plans?user_id=${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const getConsumerPaymentPlanDetails = (user, id) =>
  oauth2
    .fetch(`${api}/consumer/payment_plan/detail?id=${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getDownloadConsumerPaymentPlan = (user, id) =>
  oauth2
    .fetch(`${api}/consumer/payment_plan/document?document_id=${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getPaymentPlanLById = (user, id) =>
  oauth2
    .fetch(`${api}/client/payment_plans/${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const createPaymentPlan = (user, payload) =>
  fetch(`${api}/client/payment_plans`, {
    method: "POST",
    headers: setHeaders(user, true, true),
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });
export const getConsumerPaymentPlans = (user) =>
  fetch(`${api}/consumer/payment_plans`, {
    method: "GET",
    headers: setHeaders(user, true, true),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const markPaymentPlanAccepted = (user, id) =>
  fetch(`${api}/client/payment_plans/${id}/accept`, {
    method: "PUT",
    headers: setHeaders(user, true, true),
    body: JSON.stringify({
      is_accepted: true,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getClientCreditMemoList = (user, id) =>
  oauth2
    .fetch(`${api}/client/credit_memo/credit_memo_list?user_id=${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getGenerateStatementList = (user, id) =>
  oauth2
    .fetch(`${api}/client/statement/get_statement_list?id=${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const downloadStatement = (user, id) =>
  oauth2
    .fetch(`${api}/client/statement/get_statement_file?statement_id=${id}`, {
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const emailLetterStatement = (user, payload) =>
  oauth2
    .fetch(`${api}/client/statement/send_statement`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const generateStatement = (user, id, from, to) =>
  oauth2
    .fetch(
      `${api}/client/statement/create_stmt?id=${id}&from_date=${from}&to_date=${to}`,
      {
        method: "POST",
        headers: setHeaders(user, true, true),
        body: JSON.stringify({}),
      }
    )
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getCreditMemoDetails = (user, id) =>
  oauth2
    .fetch(`${api}/client/credit_memo/cm_detail?id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const addCreditMemoNote = (user, payload) =>
  oauth2
    .fetch(`${api}/client/credit_memo_notes`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const addPaymentPlanNotes = (user, payload) =>
  oauth2
    .fetch(`${api}/client/payment_plans/${payload.payment_plan_id}/notes`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const getCreditMemoNumber = (user) =>
  oauth2
    .fetch(`${api}/client/credit_memo/getCMCreateData`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const addCreditMemo = (user, payload) =>
  oauth2
    .fetch(`${api}/client/credit_memo/create_cm`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const sendPastDueRemainder = (user, payload) =>
  oauth2
    .fetch(`${api}/client/invoice/past_due_task?task_id=${payload.task_id}`, {
      method: "POST",
      headers: setHeaders(user, true, false),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const cancelPastDueRemainder = (user, payload) =>
  oauth2
    .fetch(
      `${api}/client/invoice/cancel_past_due_task?task_id=${payload.task_id}`,
      {
        method: "POST",
        headers: setHeaders(user, true, false),
        body: JSON.stringify({
          ...payload,
        }),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const deleteClientsCreditMemo = (user, id) =>
  oauth2
    .fetch(`${api}/client/credit_memo/delete_cm?credit_memo_id=${id}`, {
      method: "DELETE",
      headers: setHeaders(user, true, false),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getOrgMergeVeriableData = (user, id) =>
  oauth2
    .fetch(`${api}/client/consumers/${id}/org_merge_vars`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const signInWithIntuit = () =>
  fetch(`${api}/qb_sso?return_url=${window.location.origin}`, {
    method: "GET",
    headers: setHeaders(null, false, false),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const setOauthSSOObj = (payload) => {
  if (
    (payload.email != "null" && payload.email != null && payload.email != "") ||
    (payload.id_token != "null" &&
      payload.id_token != null &&
      payload.id_token != "") ||
    (payload.openid_id != "null" &&
      payload.openid_id != null &&
      payload.openid_id != "")
  ) {
    oauth2 = new OAuth2({
      grantType: "password",
      clientId: clientID,
      clientSecret: clientSecret,
      username: payload.email,
      id_token: payload.id_token,
      openid_id: payload.openid_id,
      tokenEndpoint: `${api}/oauth/token?organization_key=${payload.organization_key}&id_token=${payload.id_token}&openid_id=${payload.openid_id}&username=${payload.email}`,
      onTokenUpdate: (token) => {
        cookies.remove("accessToken", { path: "/", secure: true });
        cookies.remove("refreshToken", { path: "/", secure: true });
        cookies.set("accessToken", token.accessToken, {
          path: "/",
          secure: true,
        });
        cookies.set("refreshToken", token.refreshToken, {
          path: "/",
          secure: true,
        });
      },
      onAuthError: (error) => {
        if (window.location.pathname !== "/login") {
          Emitter.emit("OAUTH_ERROR", true);
        }
      },
    });
  } else {
    // window.location.href = "/login";
  }
};

export const setOauthSSOObjNewUser = (payload) => {
  oauth2 = new OAuth2({
    grantType: "password",
    clientId: clientID,
    clientSecret: clientSecret,
    username: payload.email,
    password: payload.password,
    tokenEndpoint: `${api}/oauth/token?organization_key=${payload.organization_key}&username=${payload.email}&password=${payload.password}`,
    onTokenUpdate: (token) => {
      cookies.remove("accessToken", { path: "/", secure: true });
      cookies.remove("refreshToken", { path: "/", secure: true });
      cookies.set("accessToken", token.accessToken, {
        path: "/",
        secure: true,
      });
      cookies.set("refreshToken", token.refreshToken, {
        path: "/",
        secure: true,
      });
    },
  });
};

export const reverseCreditMemo = (user, payload) =>
  oauth2
    .fetch(
      `${api}/client/credit_memo/reverse_cm?invoice_id=${payload.invoice_id}&txn_id=${payload.txn_id}&credit_memo_number=${payload.credit_memo_number}`,
      {
        method: "delete",
        headers: setHeaders(user, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getAppliedCredit = (user) =>
  oauth2
    .fetch(
      `${api}/client/credit_memo/apply_credit_memo?user_id=${user.user_id}`,
      {
        method: "GET",
        headers: setHeaders(user.user_id, true, false),
      }
    )
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const saveSubdomain = (user, payload) =>
  oauth2
    .fetch(`${api}/save_subdomain`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getOrganizationData = () =>
  fetch("./theme.json")
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

// export const getOrgDetail = (user) => {
//   oauth2
//     .fetch(`${api}/client/org/detail?org_url=${getBaseUrl()}`, {
//       method: "GET",
//       headers: setHeaders(
//         user == null ? null : user, // user data
//         user == null ? false : true, // use auth
//         false // use JSON
//       ),
//     })
//     .then((response) => {
//       alert("response==========:", response);
//       if (response) {
//         alert("is resp==========:", response);
//         response.json();
//       }
//     })
//     .then(function (data) {
//       alert("data==========:", typeof data);
//       if (data) {
//         alert("is data==========:", typeof data);
//       }
//       return data;
//     })
//     .catch((error) => {
//       alert("error==========:", error);
//       return error;
//     });
// };

export const ssoAddOrganization = (user, payload) =>
  oauth2
    .fetch(`${api}/client/org/update`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getOrgDetails = (user) =>
  fetch(`${api}/client/org/detail?org_url=${getBaseUrl()}`, {
    method: "GET",
    headers: setHeaders(
      user == null ? null : user, // user data
      true, // use auth
      false // use JSON
    ),
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getRealmIdFromQB = (realmid) =>
  fetch(`${api}/launch?realmid=${realmid}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const getEmpInfo = (token) =>
  fetch(`${api}/client/employee/info?registration_token=${token}`, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

export const resetPasswords = (user, employee_id) =>
  oauth2
    .fetch(`${api}/client/employee/resetPassword`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        employee_id: employee_id,
      }),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      // console.error("error==========:", error);
      return { errors: error.message };
    });
export const invitation = (user, employee_id) =>
  oauth2
    .fetch(`${api}/client/employee/invitation`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        employee_id: employee_id,
      }),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const userDisable = (user, employee_id) =>
  oauth2
    .fetch(`${api}/client/employee/disable`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        employee_id: employee_id,
      }),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const editUser = (user, id) =>
  oauth2
    .fetch(`${api}/client/employee/${id}/data`, {
      method: "GET",
      headers: setHeaders(user.user_id, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const assignedConstactsApi = (user, id) =>
  oauth2
    .fetch(`${api}/client/consumer/unassigned?employee_id=${id}`, {
      method: "GET",
      headers: setHeaders(user.user_id, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const getMergeVariablesListClient = (user, id) =>
  oauth2
    .fetch(`${api}/client/mergeVariables/list?organization_id=${id}`, {
      method: "GET",
      headers: setHeaders(user, true, false),
    })
    .then((response) => response.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const updateMergeVariablesClient = (user, payload) =>
  oauth2
    .fetch(`${api}/client/mergeVariables/update`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const updateW9Form = (user, payload) =>
  oauth2
    .fetch(`${api}/client/org/w9_form`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const paymentPortalUrl = (user, payload) =>
  oauth2
    .fetch(`${api}/client/mergeVariables/pay_portal`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const mergeVariables_org_logo = (user, payload) =>
  oauth2
    .fetch(`${api}/client/mergeVariables/org_logo`, {
      method: "PUT",
      headers: setHeaders(user, true, false),
      body: payload,
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const mergeVar_pdfPreview = (user, employee_id) =>
  oauth2
    .fetch(`${api}/client/mergeVariables/pdf_preview`, {
      method: "POST",
      headers: setHeaders(user, true, true),
    })
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const update_profile = (user, payload) =>
  oauth2
    .fetch(`${api}/client/org/update_profile`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ ...payload }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

// export const getTestCredsFullPortal = (user) =>
//   oauth2
//     .fetch(`${api}/client/organization/get_test_creds?full_portal=true`, {
//       method: "GET",
//       headers: setHeaders(user, true, false),
//     })
//     .then((res) => res.json())
//     .catch((error) => {
//       console.error("error==========:", error);
//     });

export const usersAgreement = (userID, user) =>
  oauth2
    .fetch(`${api}/users/${userID}/agreement`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({ accepted: true }),
    })
    .then((response) => response.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const updateAccountPlacementEligibility = (user, payload) =>
  oauth2
    .fetch(`${api}/client/org/acc_elg_vars`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientMandrillRequest = (payload) =>
  fetch(`${api}/client/mandrill/leadRequest`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const clientPlacementTerm = (user) =>
  fetch(`${api}/client/org/placementTerm`, {
    headers: setHeaders(user, true, true),
    method: "POST",
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const cancelPlacementFromCollection = (user, id) =>
  fetch(`${api}/client/invoice/cancelPlacement?invoice_id=${id}`, {
    headers: setHeaders(user, true, true),
    method: "PUT",
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const acceptConsumerPaymentPlan = (user, payload) =>
  oauth2
    .fetch(`${api}/consumer/payment_plan/accept`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });

export const contactUs = (user, payload) =>
  oauth2
    .fetch(`${api}/contact_us`, {
      method: "POST",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const updateAccountingSystem = (user, payload) =>
  oauth2
    .fetch(`${api}/client/org/update_accounting_system`, {
      method: "PUT",
      headers: setHeaders(user, true, true),
      body: JSON.stringify({
        ...payload,
      }),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
export const getAccountingSystem = (user) =>
  oauth2
    .fetch(`${api}/client/org/accounting_system`, {
      method: "GET",
      headers: setHeaders(user, true, true),
    })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
